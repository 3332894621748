<div class="connect-to-calendar-modal" {planIds}>
    <Modal
        id="connect-to-calendar-modal-id"
        bind:enabled={showConnectToCalendarModal}
        titleText={i18n.connectToCalendar}
        confirmText={i18n.connect}
        cancelText={i18n.cancel}
        on:close={connectToCalendarModalClicked}
    >
        <div slot="body">
            <Banner
                visible={bannerVisible}
                type="warning"
                closable={false}
                text={i18n.connectToCalendarBannerWarningText}
            />
            <div class="connect-calendar-modal-section__description">
                {i18n.connectToCalendar_Description}
            </div>
            <div class={dateTimePickerModalFromSection}>
                <DatePickerField
                    bind:value={dateValueFrom}
                    bind:expanded={datePickerFromExpanded}
                    bind:highlightedDates
                    firstDayOfWeek={config.firstDayOfWeek}
                    locale={config.locale}
                    id="date-picker-from"
                    label={i18n.calendarFrom}
                    labelPosition="left"
                    placeholder={i18n.datePickerPlaceholder}
                    format={dateFormat}
                    closeOnSelect={true}
                    {i18n}
                />
            </div>
            <div class={dateTimePickerModalToSection}>
                <DatePickerField
                    bind:value={dateValueTo}
                    bind:expanded={datePickerToExpanded}
                    bind:highlightedDates
                    firstDayOfWeek={config.firstDayOfWeek}
                    locale={config.locale}
                    id="date-picker-to"
                    label={i18n.calendarTo}
                    labelPosition="left"
                    placeholder={i18n.datePickerPlaceholder}
                    format={dateFormat}
                    closeOnSelect={true}
                    {i18n}
                />
            </div>
            <ConnectToCalendarModalError
                {showStartStopErrorMessage}
                {setValidDatesErrorMessage}
                {showStartInvalidMessage}
                {showStopInvalidMessage}
                {i18n}
            />
            <ConnectToCalendarSelector
                bind:availableEvents
                {i18n}
                on:firstEventIdBySelection={changeFirstEventId}
            />
            {#if showEmptyState}
                <EmptyState
                    iconName="events"
                    title={i18n.connectToCalendarEmptyStateHeading}
                    description={i18n.connectToCalendarEmptyStateDescription}
                    showLink={true}
                    linkText={i18n.connectToCalendarEmptyStateLinkText}
                    href={config.calendarUrl}
                />
            {/if}
        </div>
    </Modal>
</div>

<script>
    import DatePickerField from "@itslearning/prometheus/assets/inputs/DatePickerField/v1/DatePickerField.svelte";
    import Modal from "@itslearning/prometheus/assets/modals/Modal/v2/Modal.svelte";
    import Banner from "@itslearning/prometheus/assets/feedback/Banner/v1/Banner.svelte";
    import EmptyState from "@itslearning/prometheus/assets/nodes/EmptyState/v1/EmptyState.svelte";
    import ConnectToCalendarModalError from "./ConnectToCalendarModalError.svelte";
    import ConnectToCalendarSelector from "./ConnectToCalendarSelector.svelte";
    import config from "../../config";
    import { dateTimePickerModalFromSection, dateTimePickerModalToSection } from "../../constants";
    import {
        formatDateOnlyForApi,
        getEventsDateRangeString,
        validateDateFormat
    } from "../../helpers/dateHelper";
    import { createEventDispatcher, onMount } from "svelte";
    import { getCalendarAvailableDates } from "../../api";

    export let showConnectToCalendarModal;
    export let i18n;
    export let planIds;

    let dateValueFrom;
    let dateValueFromInitially;
    let datePickerFromExpanded = false;
    let dateValueTo;
    let dateValueToInitially;
    let datePickerToExpanded = false;

    let mountComplete = false;
    let showStartStopErrorMessage = false;
    let setValidDatesErrorMessage = false;
    let showStartInvalidMessage = false;
    let showStopInvalidMessage = false;
    let showEmptyState = false;

    let connectingEvents = [];
    let allEvents = [];
    let availableEvents = [];
    let highlightedDates = [];
    let isDateDisabled = false;

    let firstEventId = undefined;
    let bannerVisible = false;

    const dateFormat = config.currentDateFormat;

    const dispatch = createEventDispatcher();

    $: showEmptyState =
        !firstEventId &&
        dateValueFrom &&
        dateValueTo &&
        !showStartStopErrorMessage &&
        !showStartInvalidMessage &&
        !showStopInvalidMessage &&
        !setValidDatesErrorMessage;

    $: {
        const datePickerFrom = dateValueFrom ? new Date(dateValueFrom) : undefined;
        const datePickerTo = dateValueTo ? new Date(dateValueTo) : undefined;

        if (!datePickerFrom || !datePickerTo) {
            showStartStopErrorMessage = false;
            showStartInvalidMessage = false;
            showStopInvalidMessage = false;
        } else {
            // Use epoch time to compare time (ms since 1970)
            const epochStart = datePickerFrom.valueOf();
            const epochStop = datePickerTo.valueOf();

            setValidDatesErrorMessage = false;
            showStartInvalidMessage = false;
            showStopInvalidMessage = false;
            showStartStopErrorMessage = epochStart > epochStop;
        }
    }

    $: if (
        mountComplete &&
        dateValueFrom &&
        dateValueFromInitially !== dateValueFrom &&
        !datePickerFromExpanded
    ) {
        getCourseEventsInCurrentTimespan(false);
    }

    $: if (
        mountComplete &&
        dateValueTo &&
        dateValueToInitially !== dateValueTo &&
        !datePickerToExpanded
    ) {
        getCourseEventsInCurrentTimespan(false);
    }

    $: if (availableEvents) {
        connectingEvents = availableEvents.filter(
            p => p.eventConnected === true && p.eventOccupied === false
        );

        isDateDisabled = connectingEvents.length > 0;
    }

    onMount(async () => {
        dateValueFrom = new Date(Date.now());

        await getAllCourseEvents();

        mountComplete = true;
    });

    function connectToCalendarModalClicked(event) {
        if (event.detail.confirmed === true) {
            if (!dateValueFrom || !dateValueTo) {
                setValidDatesErrorMessage = true;
                showConnectToCalendarModal = true;
            } else {
                setValidDatesErrorMessage = false;

                const datePickerFrom = new Date(dateValueFrom);
                const datePickerTo = new Date(dateValueTo);

                const fromDatePickerInputField = "prom-input-date-picker-from-input";
                const toDatePickerInputField = "prom-input-date-picker-to-input";

                const dateFrom = document.getElementById(fromDatePickerInputField).value;
                const dateTo = document.getElementById(toDatePickerInputField).value;

                const validateDateToFormat = validateDateFormat(dateTo, dateFormat);
                const validateDateFromFormat = validateDateFormat(dateFrom, dateFormat);

                if (!validateDateFromFormat) {
                    showStartInvalidMessage = true;
                    showStopInvalidMessage = false;
                    showConnectToCalendarModal = true;

                    return;
                }

                if (!validateDateToFormat) {
                    showStopInvalidMessage = true;
                    showStartInvalidMessage = false;
                    showConnectToCalendarModal = true;

                    return;
                }

                if (!firstEventId) {
                    bannerVisible = true;
                    showConnectToCalendarModal = true;

                    return;
                }

                // Only whole days
                datePickerFrom.setHours(0, 1);
                datePickerTo.setHours(23, 59);

                // Use epoch time to compare time (ms since 1970)
                const epochStart = datePickerFrom.valueOf();
                const epochStop = datePickerTo.valueOf();

                // We need to check that the selected time is valid as well
                showStartStopErrorMessage = epochStart > epochStop ? true : false;

                if (showStartStopErrorMessage) {
                    showConnectToCalendarModal = true;
                }

                if (firstEventId > 0) {
                    // Only possible to have one item in new planner (despite use of array)
                    const activityArray = [];

                    activityArray.push(firstEventId.toString());

                    dispatch("connectToCalendar", {
                        activityIds: activityArray
                    });
                }
            }
        } else {
            dispatch("close");
        }
    }

    // Function to get course events in the currently selected timespan
    // As long as selected start or stop time is within event range it should also appear
    function getCourseEventsInCurrentTimespan(setConnectingEvents) {
        const datePickerFrom = dateValueFrom ? new Date(dateValueFrom) : undefined;
        const datePickerTo = dateValueTo ? new Date(dateValueTo) : undefined;

        if (!datePickerFrom || !datePickerTo) {
            return;
        }

        // Only whole days
        datePickerFrom.setHours(0, 1);
        datePickerTo.setHours(23, 59);

        const updatedAvailableEvents = [];

        allEvents.forEach(function (event) {
            if (
                (datePickerFrom >= event.eventFrom && datePickerFrom <= event.eventTo) ||
                (datePickerTo >= event.eventFrom && datePickerTo <= event.eventTo) ||
                (event.eventFrom >= datePickerFrom && event.eventTo <= datePickerTo)
            ) {
                updatedAvailableEvents.push({
                    eventId: event.eventId,
                    eventOccupied: event.eventOccupied,
                    eventConnected: setConnectingEvents ? event.eventConnected : false,
                    eventDate: event.eventDate,
                    eventTitle: event.eventTitle,
                    groupId: event.groupId,
                    participants: event.participants,
                    eventFrom: event.eventFrom,
                    eventTo: event.eventTo
                });
            }
        });
        availableEvents = updatedAvailableEvents;
    }

    // Function to get all available course events
    async function getAllCourseEvents() {
        if (!config.hasFunctionalityCalendar) {
            return;
        }

        const datePickerMin = new Date(1999, 1, 1);
        const datePickerMax = new Date(2999, 12, 31);

        formatDateOnlyForApi(datePickerMin, datePickerMax);

        const events = await getCalendarAvailableDates(datePickerMin, datePickerMax);

        const pickerHighlightedDates = [];
        const allCourseEvents = [];

        let multipleDaysPlan = false;
        let firstEventStartDate = null;
        let firstEventStopDate = null;

        if (events.length > 0) {
            firstEventStartDate = new Date(events[0].Start);
            firstEventStopDate = new Date(events[0].Stop);

            if (firstEventStartDate != firstEventStopDate) {
                multipleDaysPlan = true;
            }
        }

        // Check if any of the dates differs so we need to include date (in addition to time)
        if (firstEventStartDate && !multipleDaysPlan) {
            let curentEventStartDate = null;
            let currentEventStopDate = null;

            events.forEach(function (event) {
                curentEventStartDate = new Date(event.Start);
                currentEventStopDate = new Date(event.Stop);

                if (
                    curentEventStartDate !== firstEventStartDate ||
                    currentEventStopDate !== firstEventStartDate
                ) {
                    multipleDaysPlan = true;
                }
            });
        }

        events.forEach(function (event) {
            const eventFrom = event.Start ? new Date(event.Start) : undefined;
            const eventTo = event.Stop ? new Date(event.Stop) : undefined;

            if (eventFrom && eventTo) {
                const formattedDate = getEventsDateRangeString(
                    eventFrom,
                    eventTo,
                    config.locale,
                    multipleDaysPlan
                );

                let isOccupied = false;
                let isConnected = false;

                if (event.PlanId === config.planId) {
                    isConnected = true;
                } else {
                    if (event.PlanId && event.PlanId !== 0 && !event.PlanDeleted) {
                        isOccupied = true;
                    }
                }

                allCourseEvents.push({
                    eventId: event.EventId,
                    eventOccupied: isOccupied,
                    eventConnected: isConnected,
                    eventDate: formattedDate,
                    eventTitle: event.EventTitle,
                    groupId: event.GroupId,
                    participants: event.GroupTitle,
                    eventFrom: eventFrom,
                    eventTo: eventTo
                });

                if (
                    event &&
                    (event.PlanId === 0 || event.PlanId === config.planId || event.PlanDeleted)
                ) {
                    const epochStart = eventFrom.valueOf();
                    const epochStop = eventTo.valueOf();

                    const dayCounter = Math.floor((epochStop - epochStart) / (1000 * 60 * 60 * 24));

                    if (dayCounter > 0) {
                        for (let i = 0; i <= dayCounter; i++) {
                            const currentDatePointer = new Date();

                            currentDatePointer.setTime(epochStart + i * (1000 * 60 * 60 * 24));
                            pickerHighlightedDates.push(currentDatePointer);
                        }
                    } else {
                        pickerHighlightedDates.push(eventFrom);
                    }
                }
            }
        });
        highlightedDates = pickerHighlightedDates;
        allEvents = allCourseEvents;
        getCourseEventsInCurrentTimespan(true);
    }

    function changeFirstEventId(event) {
        firstEventId = event.detail.firstEventId;
        bannerVisible = false;
    }
</script>
