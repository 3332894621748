<div class="itsl-topic__plans-sorting">
    {#if config.canEdit}
        <Select
            label={i18n.sortBy}
            id="SortById"
            bind:value={planOrder}
            disabled={false}
            on:change={changeValue}
        >
            <option value={planSorting.CreationDateValue}>
                {i18n.creationDate}
            </option>
            {#if !isSelfPacedCourse}
                <option value={planSorting.DateAscendingValue}>
                    {i18n.dateAscending}
                </option>
                <option value={planSorting.DateDescendingValue}>
                    {i18n.dateDescending}
                </option>
            {/if}
            <option value={planSorting.AlphabeticallyAZValue}>
                {i18n.alphabeticallyAZ}
            </option>
            <option value={planSorting.AlphabeticallyZAValue}>
                {i18n.alphabeticallyZA}
            </option>
            {#if showYourOwnSorting}
                <option value={planSorting.YourOwnSortingValue}>
                    {i18n.yourOwnSorting}
                </option>
            {/if}
        </Select>
    {/if}
</div>

<script>
    import { createEventDispatcher } from "svelte";

    import Select from "@itslearning/prometheus/assets/inputs/Select/v1/Select.svelte";
    import config from "@its-ui/planner-common/config";
    import { planSorting, columnTypes, columnVisibility } from "@its-ui/planner-common/constants";

    import { setPlanOrderInTopic } from "@its-ui/planner-common/api";

    export let i18n;
    export let topicId;
    export let planOrder;
    export let showYourOwnSorting;

    const isSelfPacedCourse =
        config.columnVisibilities[columnTypes.Date] === columnVisibility.Nobody;

    const dispatch = createEventDispatcher();

    const changeValue = async event => {
        if (event.detail) {
            await setPlanOrderInTopic(topicId, event.detail);

            dispatch("changePlanOrder", { planOrder: event.detail });
        }
    };
</script>
