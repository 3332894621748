<div class="its-plans__topic-learning-objectives">
    {#if field.learningObjectives}
        {#each field.learningObjectives as lo (lo.id)}
            <LearningObjective
                id={lo.id}
                title={lo.title}
                description={lo.description}
                isFolder={lo.cssClass === "Category" || lo.type === learningObjectiveType.Category}
                isTooltipDialogOpened={showTooltipDialog}
                on:openTooltipDialog={openTooltipDialog}
                on:closeTooltipDialog={closeTooltipDialog}
            />
        {/each}
        <div class="its-plans__topic-learning-objectives__tooltipdialog-container">
            <TooltipDialog
                show={showTooltipDialog}
                element={learningObjectiveElement}
                placement={tooltipDialogPlacement}
                idLO={idLearningObjective}
                heading={headingTooltipDialog}
                text={textTooltipDialog}
                closeButtonText={i18n.close}
                showActions={config.canEdit}
                linkText={i18n.goToProgressReport}
                actionButtonText={i18n.remove}
                actionButtonLabel={i18n.removeFromTopic}
                courseId={config.courseId}
                on:delete={removeLearningObjective(idLearningObjective)}
                on:close={closeTooltipDialog}
            />
        </div>
    {/if}
    {#if canEdit}
        <IconButton
            type="plain"
            htmlType="button"
            iconClass="add-lo-button"
            text={i18n.addLearningObjectives}
            on:click={showAddLOModal}
            disabled={losDirty}
            loading={losDirty}
        />
        <LearningObjectivePicker
            bind:enabled={loPickerEnabled}
            {rootLearningObjective}
            initialLearningObjectives={field.learningObjectives}
            {getLearningObjectives}
            initialSelectionDisabled={true}
            {i18n}
            on:apply={e => applyNewLearningObjectives(e.detail)}
        />
    {/if}
</div>

<script>
    import IconButton from "@itslearning/prometheus/assets/inputs/IconButton/v1/IconButton.svelte";
    import LearningObjectivePicker from "@itslearning/prometheus/assets/modals/LearningObjectivePicker/v1/LearningObjectivePicker.svelte";

    import { formatLanguageString } from "@itslearning/atlas/strings/format";

    import LearningObjective from "@its-ui/planner-common/components/LearningObjective/LearningObjective.svelte";
    import TooltipDialog from "@its-ui/planner-common/components/TooltipDialog/TooltipDialog.svelte";
    import { loadLearningObjectives } from "@its-ui/planner-common/controllers/learningObjectivesController";
    import {
        updateTopicFieldLearningObjectives,
        removeTopicFieldLearningObjective
    } from "@its-ui/planner-common/controllers/topicController";
    import { learningObjectiveType } from "@its-ui/planner-common/constants";

    export let field;
    export let topic;
    export let canEdit;
    export let i18n;
    export let config;

    let losDirty = false;
    let loPickerEnabled = false;

    // TooltipDialog vars
    let learningObjectiveElement = null;
    let showTooltipDialog = false;
    let idLearningObjective;
    let headingTooltipDialog;
    let textTooltipDialog;
    let tooltipDialogPlacement;

    $: rootLearningObjective = {
        id: 0,
        title: config.courseTitle,
        description: formatLanguageString(
            i18n.learningObjectivesConnectedToCourse,
            config.courseTitle
        )
    };

    async function removeLearningObjective(loId) {
        closeTooltipDialog();
        field.learningObjectives = field.learningObjectives.filter(i => i.id !== loId);

        losDirty = true;

        await removeTopicFieldLearningObjective(topic.id, field.id, loId);

        losDirty = false;
    }

    function showAddLOModal() {
        loPickerEnabled = true;
    }

    function getLearningObjectives(parentId) {
        return loadLearningObjectives(parentId, true);
    }

    async function applyNewLearningObjectives(newLearningObjectives) {
        field.learningObjectives = newLearningObjectives;

        losDirty = true;

        await updateTopicFieldLearningObjectives(topic.id, field.id, newLearningObjectives);

        losDirty = false;
    }

    export function isDirty() {
        return losDirty;
    }

    // Open/close the tooltip dialog
    function openTooltipDialog({ detail }) {
        if (detail.learningObjectiveElement) {
            learningObjectiveElement = detail.learningObjectiveElement;
            idLearningObjective = detail.id;
            headingTooltipDialog = detail.heading;
            textTooltipDialog = detail.text;
            showTooltipDialog = true;
            tooltipDialogPlacement = detail.placement;
        }
    }

    function closeTooltipDialog() {
        showTooltipDialog = false;
    }
</script>
