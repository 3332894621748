import {
    getCurrentPlans,
    getCurrentPlansPublic,
    getWithoutDatePlans,
    getWithoutDatePlansPublic,
    getPastPlans,
    getPastPlansPublic,
    getAllPlanCounts,
    getAllPlanCountsPublic,
    getAllPlans,
    getAllPlansPublic
} from "../../api";
import { loadPlans, loadPlanCounts } from "../../helpers/loadPlansHelper";
import { loadTopics as loadTopicsHelper } from "../../helpers/loadTopicsHelper";
import {
    currentPlansData,
    withoutDatePlansData,
    pastPlansData,
    topicsData,
    allPlansData,
    allPlanCountsData
} from "./stores";

export const loadCurrentPlans = (publicAccessKey = "", append = false) =>
    loadPlans(currentPlansData, publicAccessKey ? getCurrentPlansPublic : getCurrentPlans, append);

export const loadWithoutDatePlans = (publicAccessKey = "", append = false) =>
    loadPlans(
        withoutDatePlansData,
        publicAccessKey ? getWithoutDatePlansPublic : getWithoutDatePlans,
        append
    );

export const loadPastPlans = (publicAccessKey = "", append = false) =>
    loadPlans(pastPlansData, publicAccessKey ? getPastPlansPublic : getPastPlans, append);

export const loadTopics = (publicAccessKey = "") => loadTopicsHelper(topicsData, publicAccessKey);

export const loadAllPlanCounts = (publicAccessKey = "") =>
    loadPlanCounts(allPlanCountsData, publicAccessKey ? getAllPlanCountsPublic : getAllPlanCounts);

export const loadAllPlans = (publicAccessKey = "", append = false) =>
    loadPlans(allPlansData, publicAccessKey ? getAllPlansPublic : getAllPlans, append);
