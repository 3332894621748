import { get } from "svelte/store";
import {
    getPlansInTopic,
    getPlansInTopicPublic,
    getTopic,
    getTopicPublic,
    getTopics,
    getTopicsPublic
} from "../api";
import config from "../config";
import { createPlaceholderImagesProvider } from "./planImageHelper";

export function loadTopics(store, publicAccessKey) {
    store.update(data => {
        data.loading = true;
        data.error = undefined;

        return data;
    });

    store.update(data => {
        data.topics = [];

        return data;
    });

    const getPlansApiMethod = publicAccessKey ? getPlansInTopicPublic : getPlansInTopic;

    getPlansApiMethod(0)
        .then(result => {
            store.update(data => {
                data.plansWithoutTopicCount = result.total;

                return data;
            });
        })
        .catch(error => {
            store.update(data => {
                data.error = error;

                return data;
            });
        });

    const getTopicsApiMethod = publicAccessKey ? getTopicsPublic : getTopics;

    getTopicsApiMethod()
        .then(result => {
            store.update(data => {
                result = result.map(p => {
                    if (p.start) {
                        p.start = new Date(p.start);
                        p.stop = new Date(p.stop);
                    }

                    return p;
                });

                data.topics = result;
                data.loading = false;

                return data;
            });
        })
        .catch(error => {
            store.update(data => {
                data.loading = false;
                data.error = error;

                return data;
            });
        });
}

export function loadTopic(store, publicAccessKey, id) {
    store.update(data => {
        data.loading = true;
        data.error = undefined;
        data.topic = undefined;

        return data;
    });

    const apiMethod = publicAccessKey ? getTopicPublic : getTopic;

    return apiMethod(id)
        .then(result => {
            store.update(data => {
                if (result.start) {
                    result.start = new Date(result.start);
                    result.stop = new Date(result.stop);
                }

                data.topic = result;
                data.loading = false;

                return data;
            });
        })
        .catch(error => {
            store.update(data => {
                data.loading = false;
                data.error = error;

                return data;
            });
        });
}

export const loadTopicsForTableView = async ({ store, apiMethod, append } = {}) =>
    await loadPagedTopics(store, apiMethod, append);

export async function loadPagedTopics(store, apiMethod, append) {
    startLoading(store);
    let index = 0;

    if (append) {
        const { currentPageIndex } = get(store);

        index = currentPageIndex + 1;
    } else {
        store.update(data => {
            data.topics = [];
            data.placeholderImagesProvider = createPlaceholderImagesProvider(
                config.topicColours,
                config.defaultPlanImages
            );

            return data;
        });
    }

    try {
        const result = await apiMethod(index);

        applyResult(store, result, append);
    } catch (error) {
        handleError(store, error);
    }
}

function startLoading(store) {
    store.update(data => {
        data.loading = true;
        data.error = undefined;

        return data;
    });
}

function applyResult(store, result, append = false) {
    store.update(data => {
        const topics = buildUpTopics(result, data.placeholderImagesProvider);

        result.topics = append ? [...data.topics, ...topics] : topics;
        data = result;
        data.loading = false;

        return data;
    });
}

function buildUpTopics(topics, placeholderImagesProvider) {
    return topics.entityArray.map(topic => {
        setPlaceholderImageUrl(topic, placeholderImagesProvider);

        return topic;
    });
}

function setPlaceholderImageUrl(topic, placeholderImagesProvider) {
    if (!topic || !placeholderImagesProvider) {
        return;
    }

    topic.placeholderImageUrl = topic.imageUrl
        ? null
        : placeholderImagesProvider.getImageForTopic(topic);
}

function handleError(store, error) {
    console.error(error);

    store.update(data => {
        data.loading = false;
        data.error = error;

        return data;
    });

    return { error };
}
