{#if hasGroupData}
    <Select
        label={i18n.participants}
        labelPosition="left"
        id="ParticipantsConnectToCalendarId"
        bind:value={participants}
        on:change={changeValue}
    >
        {#if hasAllParticipantsData}
            <option value="0">
                {i18n.allParticipants}
            </option>
        {/if}

        {#if hasGroupData}
            {#each [...courseGroups] as [key, value]}
                <option value={key}>
                    {value.groupName}
                </option>
            {/each}
        {/if}
    </Select>
{/if}

<script>
    import Select from "@itslearning/prometheus/assets/inputs/Select/v1/Select.svelte";
    import { createEventDispatcher } from "svelte";

    export let i18n;
    export let participants;
    export let availableEvents;

    let courseGroups = new Map();
    let numberOfCourseGroups = 0;
    let numberOfAllParticipantsEvents = 0;

    const dispatch = createEventDispatcher();

    $: if (availableEvents) {
        const updatedCourseGroups = new Map();

        let firstAllParticpantsEventId = 0;
        let firstGroupEventId = 0;

        numberOfCourseGroups = 0;
        numberOfAllParticipantsEvents = 0;

        availableEvents.forEach(event => {
            if (!event.eventOccupied) {
                if (event.groupId > 0) {
                    if (!updatedCourseGroups.get(event.groupId)) {
                        updatedCourseGroups.set(event.groupId, {
                            eventId: event.eventId,
                            groupName: event.participants
                        });

                        numberOfCourseGroups = numberOfCourseGroups + 1;

                        if (firstGroupEventId === 0) {
                            firstGroupEventId = event.eventId;
                        }
                    }
                } else {
                    numberOfAllParticipantsEvents = numberOfAllParticipantsEvents + 1;

                    if (firstAllParticpantsEventId === 0) {
                        firstAllParticpantsEventId = event.eventId;
                    }
                }
            }
        });
        courseGroups = updatedCourseGroups;

        // Default setting after events reload
        if (firstAllParticpantsEventId !== 0) {
            dispatch("firstEventIdBySelection", {
                firstEventId: firstAllParticpantsEventId
            });
        } else if (firstGroupEventId !== 0) {
            dispatch("firstEventIdBySelection", {
                firstEventId: firstGroupEventId
            });
        } else {
            dispatch("firstEventIdBySelection", {
                firstEventId: null
            });
        }
    }

    $: hasGroupData = numberOfCourseGroups > 0;

    $: hasAllParticipantsData = numberOfAllParticipantsEvents > 0;

    const changeValue = async event => {
        const groupId = event.detail;

        if (groupId) {
            const groupObject = courseGroups.get(groupId);

            if (groupObject.eventId) {
                dispatch("firstEventIdBySelection", {
                    firstEventId: groupObject.eventId
                });
            }
        }
    };
</script>
