import { get } from "svelte/store";
import {
    getPlan,
    getPlanPublic,
    getPlansInTopic,
    getPlansInTopicPublic,
    getTopicCurrentPlans,
    getTopicPastPlans,
    getTopicWithoutDatePlans,
    getTopicAllPlans
} from "../api";
import config from "../config";
import { createPlaceholderImagesProvider } from "./planImageHelper";

export const loadPlansForTableView = async ({ store, apiMethod, append } = {}) =>
    loadPlans(store, apiMethod, append);

export const loadPlansForPrintTbl = async (store, apiMethod, ids) => {
    startLoading(store);

    store.update(data => {
        data.plans = [];
        data.placeholderImagesProvider = createPlaceholderImagesProvider(
            config.topicColours,
            config.defaultPlanImages
        );

        return data;
    });

    try {
        const result = await apiMethod(ids);

        applyResult(store, result);
    } catch (error) {
        handleError(store, error);
    }
};

export async function loadPlanCounts(store, apiMethod) {
    startLoading(store);

    store.update(data => {
        data.planCounts = {};

        return data;
    });

    const result = await apiMethod();

    applyResultPlanCounts(store, result);

    return result;
}

export async function loadPlans(store, apiMethod, append) {
    startLoading(store);

    let index = 0;

    if (append) {
        const { currentPageIndex } = get(store);

        index = currentPageIndex + 1;
    } else {
        store.update(data => {
            data.plans = [];
            data.placeholderImagesProvider = createPlaceholderImagesProvider(
                config.topicColours,
                config.defaultPlanImages
            );

            return data;
        });
    }

    try {
        const result = await apiMethod(index);

        applyResult(store, result, append);
    } catch (error) {
        handleError(store, error);
    }
}

export async function loadPlansForTopic(store, publicAccessKey, topicId, append, planOrder) {
    startLoading(store);

    let index = 0;

    if (append) {
        const { currentPageIndex } = get(store);

        index = currentPageIndex + 1;
    } else {
        store.update(data => {
            data.plans = [];
            data.placeholderImagesProvider = createPlaceholderImagesProvider(
                config.topicColours,
                config.defaultPlanImages
            );

            return data;
        });
    }

    const apiMethod = publicAccessKey ? getPlansInTopicPublic : getPlansInTopic;

    try {
        const result = await apiMethod(topicId, index, planOrder);

        applyResult(store, result, append, planOrder);
    } catch (error) {
        handleError(store, error);
    }
}

export async function loadPlan(store, publicAccessKey, planId) {
    startLoading(store);

    const apiMethod = publicAccessKey ? getPlanPublic : getPlan;

    try {
        const result = await apiMethod(planId);

        store.update(data => {
            if (result.start) {
                result.start = adjustToTimezone(result.start);
            }

            if (result.stop) {
                result.stop = adjustToTimezone(result.stop);
            }

            if (result.activeFromDT) {
                result.activeFromDT = adjustToTimezone(result.activeFromDT);
            }

            data.plan = result;
            data.loading = false;

            return data;
        });
    } catch (error) {
        handleError(store, error);
    }
}

export async function loadTopicCurrentPlans(store, topicId) {
    startLoading(store);

    store.update(data => {
        data.plans = [];
        data.placeholderImagesProvider = createPlaceholderImagesProvider(
            config.topicColours,
            config.defaultPlanImages
        );

        return data;
    });

    const apiMethod = getTopicCurrentPlans;

    try {
        const result = await apiMethod(topicId ? topicId : 0);

        applyResult(store, result, false);
    } catch (error) {
        handleError(store, error);
    }
}

export async function loadTopicWithoutDatePlans(store, topicId) {
    startLoading(store);

    store.update(data => {
        data.plans = [];
        data.placeholderImagesProvider = createPlaceholderImagesProvider(
            config.topicColours,
            config.defaultPlanImages
        );

        return data;
    });

    const apiMethod = getTopicWithoutDatePlans;

    try {
        const result = await apiMethod(topicId ? topicId : 0);

        applyResult(store, result, false);
    } catch (error) {
        handleError(store, error);
    }
}

export async function loadTopicPastPlans(store, topicId) {
    startLoading(store);

    store.update(data => {
        data.plans = [];
        data.placeholderImagesProvider = createPlaceholderImagesProvider(
            config.topicColours,
            config.defaultPlanImages
        );

        return data;
    });

    const apiMethod = getTopicPastPlans;

    try {
        const result = await apiMethod(topicId ? topicId : 0);

        applyResult(store, result, false);
    } catch (error) {
        handleError(store, error);
    }
}

export async function loadTopicAllPlans(store, topicId) {
    startLoading(store);

    store.update(data => {
        data.plans = [];
        data.placeholderImagesProvider = createPlaceholderImagesProvider(
            config.topicColours,
            config.defaultPlanImages
        );

        return data;
    });

    const apiMethod = getTopicAllPlans;

    try {
        const result = await apiMethod(topicId ? topicId : 0);

        applyResult(store, result);
    } catch (error) {
        handleError(store, error);
    }
}

function startLoading(store) {
    store.update(data => {
        data.loading = true;
        data.error = undefined;

        return data;
    });
}

function applyResult(store, result, append = false) {
    store.update(data => {
        const plans = buildUpPlans(result, data.placeholderImagesProvider);

        result.plans = append ? [...data.plans, ...plans] : plans;
        result.placeholderImagesProvider = data.placeholderImagesProvider;
        data = result;
        data.loading = false;

        return data;
    });
}

function applyResultPlanCounts(store, result) {
    store.update(data => {
        data = result;

        return data;
    });
}

function handleError(store, error) {
    store.update(data => {
        data.loading = false;
        data.error = error;

        return data;
    });

    return { error };
}

export function buildUpPlans(plans, placeholderImagesProvider) {
    return plans.entityArray.map(plan => {
        if (plan.start) {
            plan.start = adjustToTimezone(plan.start);
        }

        if (plan.stop) {
            plan.stop = adjustToTimezone(plan.stop);
        }

        if (plan.activeFromDT) {
            plan.activeFromDT = adjustToTimezone(plan.activeFromDT);
        }

        setPlaceholderImageUrl(plan, placeholderImagesProvider);

        return plan;
    });
}

/**
 * Set time according to configured timezone (and not current browser location)
 * @param {Date} date
 * @returns {Date}
 */
export function adjustToTimezone(date) {
    const newDate = new Date(date);
    const userTimezoneOffset = newDate.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(newDate.getTime() + userTimezoneOffset);

    return adjustedDate;
}

function setPlaceholderImageUrl(plan, placeholderImagesProvider) {
    if (!plan || !placeholderImagesProvider) {
        return;
    }

    plan.placeholderImageUrl = plan.imageUrl
        ? null
        : placeholderImagesProvider.getImageForTopic(plan.topic);
}
