﻿/**
 * File: TextEditor.js
 *
 * A control that wraps CKEditor.
 *
 * This script creates some helper methods that can be added globally
 */

/**
 * Sets up every text editor (common for all editors).
 */

/* Copy-pasted from \Itslearning\UI\Itslearning.Web.UI.Controls.Common\javascript\TextEditor.js */

/*global CKEDITOR TextEditorManager CommonFunctions CommonWindowMessages CommonConstants editorInstance IsEditorEmpty*/

/* eslint-disable */
(function (window) {
    let startupFunctions = {},
        setupDone = false,
        style,
        manager = (window.TextEditorManager = {
            /**
             * JSON-serialized settings, which are common for all editor instances.
             */
            commonSettings: undefined,

            /**
             * Adds startup function, which will be called on CKEditor instanceReady event.
             */
            addStartupFunction: function (editorName, callbackFunction) {
                startupFunctions[editorName] = callbackFunction;
            },

            /**
             * Initial setup of all instances of Text Editor.
             */
            textEditorSetup: function () {
                if (setupDone) {
                    return;
                }

                setupDone = true;

                if (!window.CKEDITOR) return;

                CKEDITOR.on("dialogDefinition", function (e) {
                    const definition = e.data.definition,
                        loadFunction = definition.onLoad,
                        showFunction = definition.onShow;

                    definition.onLoad = function () {
                        const instance = this;

                        setupButton(instance, "ok", manager.commonSettings.cssClasses.ok);
                        setupButton(instance, "cancel", manager.commonSettings.cssClasses.cancel);

                        if (loadFunction) {
                            return loadFunction.apply(instance, arguments);
                        }
                    };

                    definition.onShow = function () {
                        const instance = this;

                        if (!(CKEDITOR.env.iOS && /OS 1[0-2]_/.test(navigator.userAgent))) {
                            const dialogElement = instance.getElement();

                            if (dialogElement && dialogElement.$) {
                                const table = dialogElement.$.querySelector("table");

                                if (table) {
                                    table.scrollIntoView();
                                }
                            }
                        }

                        if (showFunction) {
                            return showFunction.apply(instance, arguments);
                        }
                    };
                });

                CKEDITOR.on("instanceReady", function (e) {
                    const callbackFunction = startupFunctions[e.editor.name];

                    if (callbackFunction) {
                        callbackFunction();
                    }

                    e.editor.on("fileUploadRequest", function (evt) {
                        const xhr = evt.data.fileLoader.xhr;

                        xhr.setRequestHeader(
                            TextEditorManager.commonSettings.antiForgeryHeader.name,
                            TextEditorManager.commonSettings.antiForgeryHeader.value
                        );
                    });
                });

                CommonFunctions.attachPostMessageEventListener(
                    window,
                    CommonWindowMessages.MessageNames.textEditorInteraction,
                    function (data) {
                        switch (data.interactionType) {
                            case CommonWindowMessages.TextEditorInteractionType.CloseDialog:
                                if (data.needExpandAndCollapseExtensionIframe) {
                                    collapseExtensionIframe();
                                }

                                window.CloseDialog();

                                return;
                            case CommonWindowMessages.TextEditorInteractionType.InsertHtml:
                                window.InsertHtml(
                                    data.htmlContent,
                                    data.editorInstanceId,
                                    data.isEmbeddedImage
                                );
                                break;
                            case CommonWindowMessages.TextEditorInteractionType.InsertLinkToFile:
                                var attributes = {
                                    href: data.linkUrl,
                                    target: "_blank"
                                };
                                insertLink(
                                    CKEDITOR.dialog.getCurrent()._.editor,
                                    data.linkName,
                                    attributes
                                );
                                break;
                            case CommonWindowMessages.TextEditorInteractionType
                                .UpdateItslPluginsList:
                                if (data.editorInstanceId && data.editorInstanceId != "") {
                                    window.editorInstance =
                                        CKEDITOR.instances[data.editorInstanceId];
                                } else {
                                    window.editorInstance = CKEDITOR.dialog.getCurrent()._.editor;
                                }

                                if (editorInstance.plugins.itslpluginlist) {
                                    editorInstance.plugins.itslpluginlist.update(
                                        editorInstance,
                                        data.pluginId
                                    );
                                }

                                break;
                            case CommonWindowMessages.TextEditorInteractionType
                                .InsertImageToSlideshow:
                                var editor = null;

                                if (data.editorInstanceId && data.editorInstanceId.length > 0) {
                                    editor = CKEDITOR.instances[data.editorInstanceId];
                                } else {
                                    editor = CKEDITOR.dialog.getCurrent()._.editor;
                                }

                                if (editor != null) {
                                    if (data.closeDialogAfterPerformingOperation) {
                                        window.CloseDialog();
                                        data.closeDialogAfterPerformingOperation = false;
                                    }

                                    CKEDITOR.dialog
                                        .getCurrent()
                                        .insertImageToSlideshow(data.extendedData);
                                }

                                break;
                            case CommonWindowMessages.TextEditorInteractionType.InsertTextLink:
                                var linkAttributes = {
                                    href: data.linkUrl,
                                    target: data.linkTarget,
                                    "data-cke-saved-href": ""
                                };

                                if (data.linkAdditionalAttributes) {
                                    Object.assign(linkAttributes, data.linkAdditionalAttributes);
                                }

                                insertLink(
                                    CKEDITOR.dialog.getCurrent()._.editor,
                                    data.linkText,
                                    linkAttributes
                                );

                                if (data.needExpandAndCollapseExtensionIframe) {
                                    collapseExtensionIframe();
                                }

                                break;
                            case CommonWindowMessages.TextEditorInteractionType.ScaleDialog:
                                if (data.needExpandAndCollapseExtensionIframe) {
                                    CommonFunctions.makePostMessageCallRecursivelyToTopmostFrame(
                                        window.parent,
                                        new CommonWindowMessages.GenericMessage(
                                            CommonWindowMessages.MessageNames.expandExtensionIframe
                                        )
                                    );
                                }

                                var viewSize = CKEDITOR.document.getWindow().getViewPaneSize();
                                var dialog = CKEDITOR.dialog.getCurrent();
                                var dialogSize = dialog.getSize();

                                var parentWidth = viewSize.width;
                                var parentHeight = viewSize.height;
                                var newDialogWidth = dialogSize.width;
                                var newDialogHeight = dialogSize.height;

                                if (dialogSize.width > parentWidth) {
                                    const contentWidth = dialog._.contentSize.width;
                                    const contentOffsetWidth = dialogSize.width - contentWidth;

                                    newDialogWidth = parentWidth - contentOffsetWidth;
                                    dialog.parts.contents.setStyle(
                                        "width",
                                        CKEDITOR.tools.cssLength(newDialogWidth)
                                    );
                                    dialog.parts.contents.getFirst().setStyle("width", "100%");
                                    TextEditorManager.getIframeFromDialog(dialog)
                                        .contents()
                                        .find("section.main")
                                        .width(CKEDITOR.tools.cssLength(newDialogWidth));
                                }

                                if (dialogSize.height > parentHeight) {
                                    const contentHeight = dialog._.contentSize.height;

                                    const contentOffsetHeight = dialogSize.height - contentHeight;

                                    newDialogHeight = parentHeight - contentOffsetHeight;
                                }

                                if (
                                    newDialogWidth != dialogSize.width ||
                                    newDialogHeight != dialogSize.height
                                ) {
                                    dialog.resize(newDialogWidth, newDialogHeight);
                                }

                                var dialogPosition = dialog.getPosition();

                                if (dialogSize.height > parentHeight || dialogPosition.y < 0) {
                                    dialog.move(dialogPosition.x, 0);
                                }

                                break;
                        }

                        if (data.closeDialogAfterPerformingOperation) {
                            window.CloseDialog();
                        }
                    }
                );

                CommonFunctions.attachPostMessageEventListener(
                    window,
                    CommonWindowMessages.MessageNames.expandExtensionIframeDone,
                    function () {
                        setTimeout(function () {
                            const viewSize = CKEDITOR.document.getWindow().getViewPaneSize();
                            const dialog = CKEDITOR.dialog.getCurrent();
                            const dialogSize = dialog.getSize();

                            const dialogOffsetLeft = (viewSize.width - dialogSize.width) / 2;

                            dialog.move(dialogOffsetLeft, 0, true);
                        }, 100);
                    }
                );

                function setupButton(instance, name, cssClasses) {
                    const buttonDefinition = instance.getButton(name);

                    if (buttonDefinition) {
                        const button = document.getElementById(buttonDefinition.domId);

                        button.className = cssClasses;
                        button.removeAttribute("title");
                        const span = button.getElementsByTagName("span")[0];

                        span.className = "";
                        button.append(document.createTextNode(span.innerHTML));
                        span.innerHTML = "";
                    }
                }

                function collapseExtensionIframe() {
                    CommonFunctions.makePostMessageCallRecursivelyToTopmostFrame(
                        window.parent,
                        new CommonWindowMessages.GenericMessage(
                            CommonWindowMessages.MessageNames.collapseExtensionIframe
                        )
                    );
                }

                function insertLink(editor, linkText, attributes) {
                    const selection = editor.getSelection(),
                        ranges = selection.getRanges(),
                        link = CKEDITOR.plugins.link.getSelectedLink(editor);

                    if (link) {
                        ranges[0].selectNodeContents(link);
                    }

                    if (ranges.length === 1) {
                        const range = ranges[0],
                            text = new CKEDITOR.dom.text(linkText, editor.document);

                        if (range.collapsed) {
                            range.insertNode(text);
                            range.selectNodeContents(text);
                        } else {
                            // Fix for image2 plugin (ITS-74173)
                            const widget = editor.widgets.focused;

                            if (widget && widget.name === "image") {
                                if (widget.parts.link) {
                                    widget.setData("link", null);
                                }

                                // Fix for ITS-74173
                                if (widget.data.align === "center") {
                                    widget.data.link = {
                                        linkText: linkText,
                                        type: "itslearningTreeLink"
                                    };
                                    Object.assign(widget.data.link, attributes);

                                    widget.shiftState({
                                        widget: widget,
                                        element: widget.element,
                                        oldData: widget.oldData,
                                        newData: widget.data
                                    });
                                }
                            }
                        }

                        // Apply style
                        style = new CKEDITOR.style({
                            element: "a",
                            type: CKEDITOR.STYLE_INLINE,
                            attributes: attributes
                        });
                        style.applyToRange(range);

                        selection.selectRanges(ranges);
                        editor.fire("saveSnapshot");
                    }
                }
            },

            getIframeFromDialog: function (dialog) {
                return document.getElementById(dialog._.contents.iframe.undefined.domId);
            }
        });

    manager.getOnContentLoadFunctionForIframeDialog = function (setupIframeCallback) {
        return function () {
            // Function to be run when the iframe has finished loading ( unfortunelty in case of picture plugin it takes some seconds )
            const dialog = CKEDITOR.dialog.getCurrent();

            // This check is for image dialog, if you open the plugin, and then choose upload and insert
            // When you upload a pic and press insert you would get a js error that null is not an object
            // Seems like the upload window open the plugin dialog again and use the insertimage and close the dialog before the content is fully loaded
            if (dialog) {
                const iframe = manager.getIframeFromDialog(dialog);
                const attrs = {
                    webkitallowfullscreen: "true",
                    mozallowfullscreen: "true",
                    allowfullscreen: "true",
                    allow: "microphone *; camera *"
                };

                for (const key in attrs) {
                    iframe.setAttribute(key, attrs[key]);
                }

                if (setupIframeCallback) {
                    setupIframeCallback(iframe);
                }

                try {
                    iframe.contentDocument.addEventListener("keydown", e => {
                        if (e.which === CommonConstants.keyCodes.escape) {
                            window.CloseDialog();
                        }
                    });

                    // Sets focus to iframe so user can close it with esc without clicking in
                    iframe[0].contentWindow.focus();
                } catch (ex) {
                    // do nothing
                } // Iframe belongs to another domain
            }
        };
    };

    manager.setupIframePlugin = function (configSectionName, externalOptions) {
        const extend = (a, b) => {
            // eslint-disable-next-line no-prototype-builtins
            for (const key in b) if (b.hasOwnProperty(key)) a[key] = b[key];

            return a;
        };

        const options = extend(
            {
                addToolBarButton: true,
                dialogName: undefined,
                dialogSrcReplacementFunction: undefined,
                beforeInitComplete: undefined,
                dialogContentLoad: undefined,
                emptyIframeOnClose: false,
                useBuiltInCloseButton: false
            },
            externalOptions
        );

        return {
            requires: ["iframedialog"],
            init: function (editor) {
                let pluginConfig = editor.config[configSectionName],
                    dialogDefinition = {
                        width: pluginConfig.DialogWidth,
                        height: pluginConfig.DialogHeight,
                        name: options.dialogName
                    },
                    counter = 0,
                    onContentLoad = manager.getOnContentLoadFunctionForIframeDialog(
                        function (iframe) {
                            // This is a cssClass for plugins in dashboard or page
                            if (
                                pluginConfig.IsEditorDialogTransparent &&
                                pluginConfig.IframeCssClass
                            ) {
                                iframe.classList.add(pluginConfig.IframeCssClass);
                            }

                            if (options.dialogContentLoad) {
                                options.dialogContentLoad(editor, pluginConfig, iframe);
                            }
                        }
                    );

                editor.addCommand(pluginConfig.Id, {
                    startDisabled: pluginConfig.Disabled,
                    exec: function (e) {
                        const customDialogDefinition = {
                            onShow: function () {
                                const dialog = CKEDITOR.dialog.getCurrent(),
                                    parts = dialog.parts;

                                if (options.useBuiltInCloseButton === false) {
                                    parts.close.hide(); // hide iframe x-button (close)
                                }

                                parts.footer.hide(); // hide iframe submit and cancel buttons
                                parts.title.hide(); // hide iframe title

                                if (pluginConfig.IsEditorDialogTransparent) {
                                    dialog._.element.addClass("hiddenDialog");
                                }

                                // ITS-31370: Set exact width because width=100% doesn't work properly in maximized mode
                                const iframe = TextEditorManager.getIframeFromDialog(dialog);
                                const parentDocument =
                                    (iframe.contentWindow &&
                                        iframe.contentWindow.parent.document) ||
                                    iframe.ownerDocument;
                                const ckeDialogPageContents = parentDocument.querySelectorAll(
                                    "div.cke_dialog_page_contents"
                                );

                                ckeDialogPageContents.forEach(p => {
                                    p.style.width = pluginConfig.DialogWidth + "px";
                                });

                                if (pluginConfig.DialogClass) {
                                    parentDocument
                                        .querySelectorAll("table.cke_dialog_contents")
                                        .forEach(p => {
                                            p.classList.add(pluginConfig.DialogClass);
                                        });
                                }
                            },
                            onHide: function () {
                                if (options.emptyIframeOnClose) {
                                    const iframe = TextEditorManager.getIframeFromDialog(
                                        CKEDITOR.dialog.getCurrent()
                                    );

                                    iframe.setAttribute("src", "");
                                }
                            }
                        };

                        // Add iframe each time (will overwrite) since src may change depending on whether it's contextmenu or just the button click

                        // Note: small hack - we create a new iframe each time to change the source of the iframe.
                        // We were able to change it, but it uses the one registered the first time on addIframe method call.
                        // To circumvent, we create a new one each time.
                        const iframeId = dialogDefinition.name + counter++;
                        const dialogSrc = !options.dialogSrcReplacementFunction
                            ? pluginConfig.DialogSrc
                            : options.dialogSrcReplacementFunction(editor, pluginConfig, e);

                        if (!dialogSrc) {
                            return;
                        }

                        // Add new iframe to ckeditor, and open it
                        CKEDITOR.dialog.addIframe(
                            iframeId,
                            "",
                            dialogSrc,
                            dialogDefinition.width,
                            dialogDefinition.height,
                            onContentLoad,
                            customDialogDefinition
                        );
                        e.openDialog(iframeId);
                    }
                });

                if (options.addToolBarButton) {
                    // Add button to toolbar - icon from css search for .cke_button_<pluginConfig.Id>
                    // or pass parameter "icon"
                    const buttonConfig = {
                        label: pluginConfig.Text,
                        command: pluginConfig.Id
                    };

                    if (typeof options.icon === "string") {
                        buttonConfig.icon = options.icon;
                    }

                    editor.ui.addButton(pluginConfig.Id, buttonConfig);
                }

                if (options.beforeInitComplete) {
                    options.beforeInitComplete(editor, pluginConfig);
                }
            }
        };
    };

    manager.setupContentForIframe = function () {
        window.document.addEventListener("keydown", function (e) {
            if (e.which === CommonConstants.keyCodes.escape) {
                CommonFunctions.makePostMessageCall(
                    window.parent,
                    new CommonWindowMessages.TextEditorCloseDialogMessage()
                );
            }
        });

        // Sets focus to iframe so user can close it with esc without clicking in
        window.focus();
    };

    /**
     * Extracts extension id from idStr (ex 'extension:1234'). Used by editextension and oembed plugins.
     */
    manager.getExtensionIdFromIdString = function (idStr) {
        const firstColon = idStr.indexOf(":", 0);

        if (firstColon < 0) {
            return null;
        }

        const secondColon = idStr.indexOf(":", firstColon + 1);

        if (secondColon < 0) {
            return null;
        }

        return idStr.substring(firstColon + 1, secondColon);
    };
})(window);

/**
 * Inserts html into a ckeditor instance
 */
window.InsertHtml = function InsertHtml(html, id, isEmbeddedImage) {
    let editor = null;

    if (id && id.length > 0) {
        editor = CKEDITOR.instances[id];
    } else {
        editor = CKEDITOR.dialog.getCurrent()._.editor;
    }

    if (editor != null) {
        // For embedded images we cannot send the url to the properties iframe (too long), so we only
        // send 'data:', and replace it now with the original url from the CKEditor selection
        if (isEmbeddedImage) {
            let oldImage = editor.getSelection().getSelectedElement();

            if (oldImage) {
                oldImage = oldImage.getAscendant("img", true);

                const oldUrl = oldImage.getAttribute("src");

                html = html.replace("src='data:'", "src='" + oldUrl + "'");
            }
        }

        // This is a hack for webkit
        // In Chrome and Safari the plugin content couldn't be inserted in empty editor
        // That is why we need to use SetHtml on empty editor or else the plugin would not be added.
        if ("WebkitAppearance" in document.documentElement.style) {
            if (editor.config.mask && IsEditorEmpty(editor.name, editor.config.mask)) {
                window.SetHtml(html, id);
                window.checkForMaskAndDelete(editor.name);
            } else {
                editor.insertHtml(html);
            }
        } else {
            if (CKEDITOR.env.gecko && editor.config.mask) {
                window.checkForMaskAndDelete(editor.name);
            }

            editor.insertHtml(html);
        }
    }
};

/**
 * Closes the current dialog
 */
window.CloseDialog = function CloseDialog() {
    if (window.CKEDITOR.env.webkit) {
        window.closeRecorderIfExists();
    }

    CKEDITOR.dialog.getCurrent().hide();
};

/**
 * Deletes current content of editor and inserts html instead
 */
window.SetHtml = function SetHtml(html, id, doNotSetFocus) {
    if (id && id.length > 0) {
        CKEDITOR.instances[id].setData(html, function () {
            if (!doNotSetFocus) {
                window.SetTextEditorFocus(id);
            }
        });
    } else {
        CKEDITOR.dialog.getCurrent()._.editor.setData(html, function () {
            if (!doNotSetFocus) {
                this.focus();
            }
        });
    }
};

/**
 * Get the content in the text editor
 */
window.GetTextEditorContent = function GetTextEditorContent(id) {
    if (!window.CKEDITOR) {
        const hidden = document.querySelector(`div[data-ck-id=${id}] > input[type=hidden]`);

        if (hidden.length === 1) {
            return hidden.value;
        }

        return null;
    }

    if (id && id.length > 0) {
        return CKEDITOR.instances[id].getData();
    }

    if (CKEDITOR.dialog.getCurrent()) {
        return CKEDITOR.dialog.getCurrent()._.editor.getData();
    }

    return null;
};

/**
 * Set focus to a text editor
 */
window.SetTextEditorFocus = function SetTextEditorFocus(id) {
    CKEDITOR.instances[id].focus();
};

/**
 * Destroys CKeditor in a correct manner
 */
window.RemoveEditorInstance = function RemoveEditorInstance(id) {
    if (CKEDITOR.instances[id]) {
        // This is a hack, without setting hasFocus to be false this would not work properly
        // We would get a java script fail on CKEDITOR tries to set blur it self from destroy function.
        const editable = CKEDITOR.instances[id].editable();

        if (editable.hasFocus) {
            editable.blur();
            editable.hasFocus = false;
        }

        CKEDITOR.instances[id].destroy();
    }
};

/**
 * Checks if editor is empty. If supplying a mask argument, the editor is also empty if the text in the editor is the same as the mask
 */
window.IsEditorEmpty = function IsEditorEmpty(id, mask) {
    const editor = CKEDITOR.instances[id];

    if (!editor) return;
    const txt = editor.getData().replace("\n", "");

    const encodedText = CommonFunctions.htmlEncode(txt);
    const encodedMask = CommonFunctions.htmlEncode(mask);

    return (
        "" == txt ||
        (mask && encodedText === encodedMask) ||
        "<p>&nbsp;</p>" === txt ||
        "<div>&nbsp;</div>" === txt ||
        "<p>&#160;</p>" === txt ||
        "<p></p>" === txt ||
        `<p>${encodedMask}</p>` === txt
    );
};

/**
 * Checks if editor contains mask
 */
window.IsEditorContainsMask = function IsEditorContainsMask(id, mask) {
    const editor = CKEDITOR.instances[id];

    if (!editor) return;
    const txt = editor.getData();

    return txt.indexOf(mask) > -1;
};

/**
 * Check if user has changed content in the editor
 */
window.isTextEditorDirty = function isTextEditorDirty(id) {
    let editor;

    if (id && id.length > 0 && (editor = CKEDITOR.instances[id])) {
        return editor.checkDirty();
    }

    if (CKEDITOR.dialog.getCurrent()) {
        return CKEDITOR.dialog.getCurrent()._.editor.checkDirty();
    }
};

/**
 * Check if user has changed content in any the editor
 */
window.isAnyTextEditorDirty = function isAnyTextEditorDirty() {
    if (window.CKEDITOR) {
        for (const i in CKEDITOR.instances) {
            if (CKEDITOR.instances[i].checkDirty() === true) {
                return true;
            }
        }
    }

    return false;
};

/**
 * reset the flag if editor is dirty (sets it to false)
 */
window.resetEditorIsDirty = function resetEditorIsDirty(id) {
    if (id && id.length > 0) {
        return CKEDITOR.instances[id].resetDirty();
    }

    if (CKEDITOR.dialog.getCurrent()) {
        return CKEDITOR.dialog.getCurrent()._.editor.resetDirty();
    }
};

/**
 * Resets dirty status for all editors
 */
window.resetAllTextEditorsIsDirty = function resetAllTextEditorsIsDirty() {
    if (window.CKEDITOR) {
        for (const i in CKEDITOR.instances) {
            CKEDITOR.instances[i].resetDirty();
        }
    }

    return false;
};

/**
 * set the text color (fore color) in the text editor
 */
window.setTextEditorTextColor = function setTextEditorTextColor(id, color) {
    const editor = CKEDITOR.instances[id],
        style = new CKEDITOR.style(CKEDITOR.config["colorButton_foreStyle"], { color: color });

    editor.applyStyle(style);
};

/**
 * set the text size in the text editor
 */
window.setTextEditorTextSize = function setTextEditorTextSize(id, size) {
    const editor = CKEDITOR.instances[id],
        style = new CKEDITOR.style(CKEDITOR.config["fontSize_style"], { size: size });

    editor.applyStyle(style);
};

/**
 * cleanOnFocusEvent attaches a listener for watermarking with the mask text
 */
const focusListener = function (event) {
    const mask = event.editor.config.mask;

    let contentChanged = false;

    if (IsEditorEmpty(event.editor.name, mask)) {
        window.toggleWatermark(false, event.editor);
        contentChanged = true;
    } else if (window.IsEditorContainsMask(event.editor.name, mask)) {
        const body = event.editor.getData().replace(mask, "");

        event.editor.document.getBody().setHtml(body);
        contentChanged = true;
    }

    if (contentChanged) {
        // When the user actually clicks in the editor then the mayBeDirty must be set to true for the onBeforeUnload
        event.editor.mayBeDirty = true;
        // Also, we resetDirty here so that if someone focus the editor and then clicked cancel/another link
        // it wouldn't give goAwayWarning on an empty editor
        window.resetEditorIsDirty(event.editor.name);
    }
};

const blurListener = function (event) {
    if (IsEditorEmpty(event.editor.name)) {
        if (event.editor.config.mask && event.editor.config.mask.length > 0) {
            window.toggleWatermark(true, event.editor);
            window.resetEditorIsDirty(event.editor.name);
        }
    }
};

const modeListener = function (event) {
    if (IsEditorEmpty(event.editor.name, event.editor.config.mask)) {
        window.toggleWatermark(event.editor.mode != "source", event.editor);
    }
};

window.toggleWatermark = function toggleWatermark(showWatermark, editor) {
    if (editor.mode === "wysiwyg") {
        const watermarkedClass = "watermarked";
        const editorBody = editor.document.getBody();

        if (showWatermark) {
            editorBody.setHtml(editor.config.mask);
            editorBody.addClass(watermarkedClass);
        } else {
            editorBody.setHtml("");
            editorBody.removeClass(watermarkedClass);
        }
    } else {
        if (!showWatermark) {
            editor.setData("");
        }
    }
};

window.cleanOnFocusEvent = function cleanOnFocusEvent(customMask, id, forceSetHtml) {
    const editor = CKEDITOR.instances[id];

    if (!editor) {
        return;
    }

    editor.config.mask = customMask;

    if (forceSetHtml || IsEditorEmpty(editor.name, editor.config.mask)) {
        if (!editor.focusManager.hasFocus) {
            setTimeout(function () {
                window.toggleWatermark(true, editor);
                // This mayBeDirty needs to be set to false here, because the resetDirty doesn't work correctly the first time
                // for editor with mask, the value whould be "" instead the mask it can be set too true after first focus event
                // No need to set it to false after blur again as the resetDirty there will work properly
                editor.mayBeDirty = false;
                window.resetEditorIsDirty(editor.name);
            }, 50);
        }
    }

    editor.on("focus", focusListener);
    editor.on("blur", blurListener);
    editor.on("mode", modeListener);
};

/**
 * For Safari the audio recorder needs to be closed separately, otherwise it would hang on the page
 */
window.closeRecorderIfExists = function closeRecorderIfExists() {
    const iframe = TextEditorManager.getIframeFromDialog(CKEDITOR.dialog.getCurrent());

    try {
        let recorder;

        const parentDocument = iframe.contentWindow.parent.document;

        // eslint-disable-next-line no-cond-assign
        if ((recorder = parentDocument.querySelector("object"))) {
            // id is always recorder for both audio and video
            recorder.style.display = "none";
        }
    } catch (e) {
        // do nothing
    } // Cross-domain issue - can't get iframe.contents() if iframe content is in another domain
};

/**
 * This is a cleanup function, if something where about to be inserted in the editor,
 * but the mask was not deleted the function will delete the mask
 */
window.checkForMaskAndDelete = function checkForMaskAndDelete(id) {
    let editor = null;

    if (id && id.length > 0) {
        editor = CKEDITOR.instances[id];
    } else if (CKEDITOR.dialog.getCurrent()) {
        editor = CKEDITOR.dialog.getCurrent()._.editor;
    }

    if (editor != null && editor.config.mask) {
        if (IsEditorEmpty(editor.name, editor.config.mask)) {
            editor.document.getBody().setHtml("");
        }
    }
};
