<RichText
    bind:value={field.text}
    displayValue={field.displayText}
    showTextArea={field.isPlainText}
    {i18n}
    {config}
    title={field.title}
    labelledBy="field-title-{field.id}"
    placeholder={i18n.addText}
    safeHtmlProvider={html => updateTopicFieldText(topic.id, field.id, html)}
/>

<script>
    import RichText from "@its-ui/planner-common/components/RichText/RichText.svelte";
    import { updateTopicFieldText } from "@its-ui/planner-common/controllers/topicController";

    export let field;
    export let config;
    export let i18n;
    export let topic;
</script>
