<div
    class="datetime-picker-modal__error"
    aria-live="assertive"
    role="alert"
    hidden={setValidDatesErrorMessage ? "" : "hidden"}
>
    {i18n.youMustSetValidDates}
</div>
<div
    class="datetime-picker-modal__error"
    aria-live="assertive"
    role="alert"
    hidden={showStartStopErrorMessage || showStartInvalidMessage || showStopInvalidMessage
        ? ""
        : "hidden"}
>
    {showStartStopErrorMessage
        ? i18n.errorStopDateLessThanStartDate
        : showStartInvalidMessage
          ? i18n.calendar_Recurring_invalid_from_date
          : i18n.calendar_Recurring_invalid_to_date}
</div>
<div
    class="datetime-picker-modal__error"
    aria-live="assertive"
    role="alert"
    hidden={showRecurrenceErrorMessage ? "" : "hidden"}
>
    {i18n.recurrence_TimeIntervalIsTooBig}
</div>

<script>
    export let showStartStopErrorMessage;
    export let setValidDatesErrorMessage;
    export let showStartInvalidMessage;
    export let showStopInvalidMessage;
    export let showRecurrenceErrorMessage;
    export let i18n;
</script>
