import config from "../config";
import { adjustToTimezone } from "./loadPlansHelper";
import {
    dateTimePickerModalFromSection,
    dateTimePickerModalToSection,
    datePickerButton,
    fromDatePickerInputField,
    toDatePickerInputField
} from "../constants";

/**
 * Gets a date range string for displaying two dates as a range
 * @param {Date} from
 * @param {Date} to
 * @param {string} locale
 * @param {bool} singlePlan
 * @returns {string}
 */
export function getDateRangeString(from, to, locale, singlePlan = false) {
    let fromString;
    let toString;

    if (typeof from !== "object") {
        from = adjustToTimezone(new Date(from));
    }

    if (typeof to !== "object") {
        to = adjustToTimezone(new Date(to));
    }

    // Not all browsers support the locale and options parameters for
    // toLocaleDateString so catch exceptions and use the default
    try {
        const isSameYear = from.getFullYear() === to.getFullYear();
        const isToCurrentYear = new Date().getFullYear() === to.getFullYear();

        const yearOption = isSameYear && isToCurrentYear ? undefined : "numeric";
        const options = { day: "numeric", month: "short", year: yearOption };

        fromString = from.toLocaleDateString(locale, options);
        toString = to.toLocaleDateString(locale, options);
    } catch (e) {
        fromString = from.toLocaleDateString();
        toString = to.toLocaleDateString();
    }

    if (isDateOnlyMode(from.getUTCMinutes(), to.getUTCMinutes())) {
        const dayInMilliseconds = 1000 * 60 * 60 * 24;

        if (to.getTime() - from.getTime() < dayInMilliseconds) {
            return fromString;
        }
    }

    let startTime;
    let endTime;

    try {
        const timeStringOptions = {
            minute: "numeric",
            hour: "numeric",
            hour12: config.useTwelveHourFormat
        };

        startTime = from.toLocaleTimeString(locale, timeStringOptions);
        endTime = to.toLocaleTimeString(locale, timeStringOptions);
    } catch (e) {
        startTime = from.toLocaleTimeString();
        endTime = to.toLocaleTimeString();
    }

    if (fromString === toString) {
        return `${fromString} ${startTime}–${endTime}`;
    } else {
        if (singlePlan) {
            if (isDateOnlyMode(from.getUTCMinutes(), to.getUTCMinutes())) {
                return `${fromString}–${toString}`;
            } else {
                return `${fromString} ${startTime}–${toString} ${endTime}`;
            }
        } else {
            return `${fromString}-${toString}`;
        }
    }
}

/**
 * Gets a date range string for events
 * @param {Date} from
 * @param {Date} to
 * @param {string} locale

 * @returns {string}
 */
export function getEventsDateRangeString(from, to, locale, multipleDayPlan) {
    let fromString;
    let toString;

    // Not all browsers support the locale and options parameters for
    // toLocaleDateString so catch exceptions and use the default
    try {
        const yearOption = from.getFullYear() === to.getFullYear() ? undefined : "numeric";
        const options = { day: "numeric", month: "short", year: yearOption };

        fromString = from.toLocaleDateString(locale, options);
        toString = to.toLocaleDateString(locale, options);
    } catch (e) {
        fromString = from.toLocaleDateString();
        toString = to.toLocaleDateString();
    }

    let startTime;
    let endTime;

    try {
        const timeStringOptions = {
            minute: "numeric",
            hour: "numeric",
            hour12: config.useTwelveHourFormat
        };

        startTime = from.toLocaleTimeString(locale, timeStringOptions);
        endTime = to.toLocaleTimeString(locale, timeStringOptions);
    } catch (e) {
        startTime = from.toLocaleTimeString();
        endTime = to.toLocaleTimeString();
    }

    // Only return time if plan is within one day, otherwise include date
    if (!multipleDayPlan) {
        return `${startTime}–${endTime}`;
    } else {
        if (fromString === toString) {
            return `${fromString} ${startTime}-${endTime}`;
        } else {
            return `${fromString} ${startTime}–${toString} ${endTime}`;
        }
    }
}

/**
 * Gets a date range string for displaying the start date of an upcoming plan (visibility section)
 * @param {Date} from
 * @param {Date} to
 * @param {string} locale
 * @returns {string}
 */
export function getDateStartString(from, to, locale) {
    let fromString;

    // Not all browsers support the locale and options parameters for
    // toLocaleDateString so catch exceptions and use the default
    try {
        const yearOption = from.getFullYear() === to.getFullYear() ? undefined : "numeric";
        const options = { day: "numeric", month: "short", year: yearOption };

        fromString = from.toLocaleDateString(locale, options);
    } catch (e) {
        fromString = from.toLocaleDateString();
    }

    return fromString;
}

/**
 * Format date in YYYY-MM-DD HH:MM:SS
 * @param {Date} date
 * @returns {string}
 */
export function formatDateForApi(date) {
    const month = addLeadingZero(date.getMonth() + 1);
    const day = addLeadingZero(date.getDate());
    const year = date.getFullYear();
    const hours = addLeadingZero(date.getHours());
    const minutes = addLeadingZero(date.getMinutes());
    const seconds = "00";

    const dateString = [year, month, day].join("-") + " " + [hours, minutes, seconds].join(":");

    return dateString;
}

/**
 * Format date in YYYY-MM-DD
 * @param {Date} date
 * @returns {string}
 */
export function formatDateOnlyForApi(date) {
    const month = addLeadingZero(date.getMonth() + 1);
    const day = addLeadingZero(date.getDate());
    const year = date.getFullYear();

    const dateString = [year, month, day].join("-");

    return dateString;
}

/**
 * Add leading zero to time (e.g hours or minutes)
 * @param {Date} number
 * @returns {string}
 */
export function addLeadingZero(number) {
    const zero = 2 - number.toString().length + 1;

    return Array(+(zero > 0 && zero)).join("0") + number;
}

/**
 * Return true if the day period is PM
 * @param {Date} time
 * @returns {bool}
 */
export function isPM(time) {
    const hour = time.getHours();

    if (hour > 11) {
        return true;
    }

    return false;
}

/**
 * Get the hours
 * @param {Date} time
 * @param {bool} twelveHourFormat
 * @returns {string}
 */
export function getCurrentHours(time, twelveHourFormat) {
    let hour = time.getHours();

    if (twelveHourFormat) {
        if (hour === 0) {
            hour = 12;
        } else if (hour > 11) {
            hour = hour - 12;
        }
    }

    return addLeadingZero(hour);
}

/**
 * Get the minutes
 * @param {Date} time
 * @returns {string}
 */
export function getCurrentMinutes(time) {
    const minute = time.getMinutes();

    return addLeadingZero(minute);
}

/**
 * Return true if date only mode.
 * Date mode sets minutes to :01 and :59 to indicate the time is not relevant
 * @param {Date} fromMinutes
 * @param {Date} toMinutes
 * @returns {bool}
 */
export function isDateOnlyMode(fromMinutes, toMinutes) {
    return fromMinutes === 1 || toMinutes === 59;
}

/**
 * Return true if proper date syntax (or empty date).
 * @param {Date} dateValue
 * @param {string} dateFormat
 * @returns {bool}
 */
export function validateDateFormat(dateValue, dateFormat) {
    if (dateValue === "") {
        return true;
    } else {
        let findSeparator = "";

        for (let i = 0; i < dateFormat.length; i++) {
            if (dateFormat.substring(i, i + 1) !== dateFormat.substring(0, 1)) {
                findSeparator = dateFormat.substring(i, i + 1);
                break;
            }
        }

        const separator = findSeparator;

        const dateArray = dateValue.split(separator);
        const formatArray = dateFormat.split(separator);

        let day = "";
        let month = "";
        let year = "";

        if (dateArray.length !== formatArray.length) {
            return false;
        }

        for (let i = 0; i < formatArray.length; i++) {
            if (formatArray[i] && formatArray[i].toUpperCase().includes("D")) {
                day = dateArray[i].length === 1 ? addLeadingZero(dateArray[i]) : dateArray[i];
            } else if (formatArray[i] && formatArray[i].toUpperCase().includes("M")) {
                month = dateArray[i].length === 1 ? addLeadingZero(dateArray[i]) : dateArray[i];
            } else if (formatArray[i] && formatArray[i].toUpperCase().includes("Y")) {
                if (formatArray[i].length === dateArray[i].length) {
                    year = dateArray[i];
                }
            }
        }

        const date = new Date(year + "-" + month + "-" + day);
        const missingDateValue = !day || !month || !year;

        return !isNaN(date) && !missingDateValue;
    }
}

function checkAutoSuggestFromDate(dateValueTo, dateValueFrom, dateValueFromInitially) {
    if (dateValueTo && !dateValueFromInitially) {
        dateValueFrom = dateValueTo;
    }

    dateValueFromInitially = dateValueFrom;
}

function checkAutoSuggestToDate(dateValueFrom, dateValueTo, dateValueToInitially) {
    if (dateValueFrom && !dateValueToInitially) {
        dateValueTo = dateValueFrom;
    }

    dateValueToInitially = dateValueTo;
}

/**
 * Init auto suggest from-date.
 * @param {Date} dateValueFrom
 * @param {Date} dateValueTo
 * @param {Date} dateValueFromInitially
 */
export function initAutoSuggestFromDate(dateValueFrom, dateValueTo, dateValueFromInitially) {
    document.querySelector(
        `div[class^=${dateTimePickerModalFromSection}] button[class^=${datePickerButton}]`
    ).onmousedown = checkAutoSuggestFromDate(dateValueTo, dateValueFrom, dateValueFromInitially);
    document.querySelector(
        `div[class^=${dateTimePickerModalFromSection}] button[class^=${datePickerButton}]`
    ).onkeydown = checkAutoSuggestFromDate(dateValueTo, dateValueFrom, dateValueFromInitially);
    document.querySelector(
        `div[class^=${dateTimePickerModalFromSection}] input[id^=${fromDatePickerInputField}]`
    ).onmousedown = checkAutoSuggestFromDate(dateValueTo, dateValueFrom, dateValueFromInitially);
}

/**
 * Init auto suggest to-date.
 * @param {Date} dateValueFrom
 * @param {Date} dateValueTo
 * @param {Date} dateValueToInitially
 */
export function initAutoSuggestToDate(dateValueFrom, dateValueTo, dateValueToInitially) {
    document.querySelector(
        `div[class^=${dateTimePickerModalToSection}] button[class^=${datePickerButton}]`
    ).onmousedown = checkAutoSuggestToDate(dateValueFrom, dateValueTo, dateValueToInitially);
    document.querySelector(
        `div[class^=${dateTimePickerModalToSection}] button[class^=${datePickerButton}]`
    ).onkeydown = checkAutoSuggestToDate(dateValueFrom, dateValueTo, dateValueToInitially);
    document.querySelector(
        `div[class^=${dateTimePickerModalToSection}] input[id^=${toDatePickerInputField}]`
    ).onmousedown = checkAutoSuggestToDate(dateValueFrom, dateValueTo, dateValueToInitially);
}
