<div class="its-plans-action__options">
    {#if config.canEdit}
        <div class="copy-plans-modal">
            <Modal
                id="show-copy-plans-modal-id"
                bind:enabled={showCopyPlansModal}
                titleText={i18n.copy}
                {body}
                confirmText={i18n.copy}
                cancelText={i18n.cancel}
                on:close={copyPlansModalClicked}
            >
                <div slot="body">
                    <h2 class="copy-plans-modal__section-title">{i18n.copyTheSelectedPlansTo}</h2>
                    <ButtonDropdown
                        arrow={false}
                        iconPlacement="end"
                        id="copy-plans-modal__courses-dropdown"
                        textOnly={false}
                        text={coursesDropdownText}
                        responsive={false}
                        items={coursesItems}
                        type="secondary"
                        on:beforeToggle={loadCourses}
                        on:beforeToggle={addScrollCourseDropdown}
                        on:selectedOption={selectCourse}
                    />
                    <div
                        class="copy-plans-modal__error"
                        hidden={errorNoCourseSelected ? "" : "hidden"}
                    >
                        {i18n.selectACourse}
                    </div>
                    {#if courseIsSelected}
                        {#if courseHasUseTopics}
                            <h2 class="copy-plans-modal__section-title">{i18n.selectATopic}</h2>
                            <Checkbox
                                checked={checkedKeepExistingTopic}
                                label={i18n.keepExisting}
                                small
                                on:change={updateSelectATopicSection}
                            />
                            <ButtonDropdown
                                arrow={false}
                                disabled={checkedKeepExistingTopic}
                                iconPlacement="end"
                                id="copy-plans-modal__topics-dropdown"
                                textOnly={false}
                                text={topicsDropdownText}
                                responsive={false}
                                items={topicsItems}
                                type="secondary"
                                on:beforeToggle={addScrollTopicDropdown}
                                on:selectedOption={selectTopic}
                            />
                            <div
                                class="copy-plans-modal__error"
                                hidden={errorNoTopicSelected ? "" : "hidden"}
                            >
                                {i18n.warning_SelectTopic}
                            </div>
                        {/if}
                        <h2 class="copy-plans-modal__section-title">{i18n.options}</h2>
                        {#if isDateVisible()}
                            <div class="copy-plans-modal__options-checkbox">
                                <Checkbox
                                    checked={checkedCopyDates}
                                    label={i18n.copyDatesForNewPlans}
                                    small
                                    on:change={updateCheckedStatusCopyDates}
                                />
                            </div>
                        {/if}
                        <div class="copy-plans-modal__options-checkbox">
                            <Checkbox
                                checked={checkedConnectedResources}
                                label={i18n.copyConnectedResources}
                                small
                                on:change={updateCheckedStatusConnectedResources}
                            />
                            <div class="copy-plans-modal__options-checkbox-info">
                                {i18n.copyConnectedResourcesInfoMessage}
                            </div>
                        </div>
                        {#if showAssessmentCriteriaSection}
                            <div class="copy-plans-modal__options-checkbox">
                                <Checkbox
                                    checked={checkedAssessmentCriteria}
                                    label={i18n.replaceExistingAssesmentCriteria}
                                    small
                                    on:change={updateCheckedStatusAssessmentCriteria}
                                />
                            </div>
                        {/if}
                    {/if}
                </div>
            </Modal>
        </div>

        <Modal
            id="copy-in-progress-modal-id"
            bind:enabled={showCopyInProgress}
            titleText={i18n.copy}
            confirmText={i18n.close}
            showCancel={false}
        >
            <div slot="body">
                <div class="copy-plans__copy-in-progress">
                    <span class="screen-reader">{i18n.copyInProgress}</span>
                </div>
            </div>
        </Modal>

        {#if showSetDatesModal}
            <SetDatesModal
                {showSetDatesModal}
                {i18n}
                on:setDates={saveSetDatesModalClicked}
                on:close={closeSetDatesModalClicked}
            />
        {/if}

        {#if showConnectToCalendarModal}
            <ConnectToCalendarModal
                {showConnectToCalendarModal}
                {i18n}
                planIds={$selectedPlanIds}
                on:connectToCalendar={saveConnectToCalendarModalClicked}
                on:close={closeSetDatesModalClicked}
            />
        {/if}

        <CopyConfirmAndErrorModal
            {showCopyConfirmModal}
            {i18n}
            {confirmModalBody}
            bannerVisible={confirmModalBannerVisibility}
            bannerText={confirmModalBannerText}
            on:close={closeCopyPlansConfirmModalClicked}
        />

        <Modal
            id="show-delete-plans-confirm-modal-id"
            bind:enabled={showDeletePlansConfirmModal}
            titleText={i18n.deletePlansConfirmDialog_Heading}
            bodyHtml={i18n.deletePlansConfirmDialog_Text}
            confirmText={i18n.confirm}
            cancelText={i18n.cancel}
            confirmButtonType="destructive"
            on:close={deletePlansModalClicked}
        />

        <Modal
            id="show-deactivate-elements-modal-id"
            bind:enabled={showDisableElementsConfirmModal}
            titleText={i18n.pleaseConfirm}
            bodyText={i18n.plannerPlanDeactivatedWithElementsWarning}
            confirmText={i18n.yes}
            cancelText={i18n.no}
            on:close={confirmModalClicked}
        />

        <Modal
            id="no-plans-selected-modal-id"
            bind:enabled={showNoPlansSelectedModal}
            showCancel={false}
            confirmText={i18n.ok}
            titleText={i18n.deletePlansNonSelectedDialog_Heading}
            bodyText={i18n.deletePlansNonSelectedDialog_Text}
            confirmButtonType="primary"
            on:close={noPlansModalClicked}
        />

        <ButtonDropdown
            arrow={false}
            iconPlacement="end"
            id="plans-actions-dropdown"
            items={getActions()}
            large={true}
            responsive={false}
            textOnly={false}
            text={i18n.actions}
            type="secondary"
            on:selectedOption={selectedAction}
        />
    {/if}
</div>

<script>
    import ButtonDropdown from "@itslearning/prometheus/assets/dropdowns/ButtonDropdown/v1/ButtonDropdown.svelte";
    import Checkbox from "@itslearning/prometheus/assets/inputs/Checkbox/v1/Checkbox.svelte";
    import Modal from "@itslearning/prometheus/assets/modals/Modal/v2/Modal.svelte";
    import CopyConfirmAndErrorModal from "../CopyConfirmAndErrorModal/CopyConfirmAndErrorModal.svelte";
    import SetDatesModal from "../SetDatesModal/SetDatesModal.svelte";
    import ConnectToCalendarModal from "../ConnectToCalendarModal/ConnectToCalendarModal.svelte";
    import config from "../../config";
    import i18n from "../../i18n.js";
    import { createEventDispatcher, tick } from "svelte";
    import {
        removePlansFromCourse,
        copyPlansFromCourse,
        getCoursesForCopy,
        setPlanActive,
        setPlansActive,
        updatePlansDates,
        updatePlansDatesEvents
    } from "../../controllers/updatePlanController";
    import { get } from "svelte/store";
    import { getTopicsForCopyPlans } from "../../api";
    import {
        endpoints,
        plansActionType,
        plansCopyActionModal,
        columnTypes,
        columnVisibility,
        plannerViews
    } from "../../constants";
    import { isMobile } from "@itslearning/atlas/detectors/UserAgentHelper";
    import { isDateVisible } from "../../helpers/visibilityHelper";
    import { getPlansOrderFromPlansView } from "../../helpers/converters.js";

    const dispatch = createEventDispatcher();

    export let printAsTopic = false;
    export let selectedView = 0;
    export let data;
    export let areAllPlansSelected = false;
    export let topicFilterIds = [];
    export let selectedPlanIds = {
        set: () => {}
    };

    let showCopyPlansModal = false;
    let showCopyConfirmModal = false;
    let showDeletePlansConfirmModal = false;
    let showNoPlansSelectedModal = false;
    let showSetDatesModal = false;
    let showConnectToCalendarModal = false;

    let body;
    let errorNoCourseSelected = false;
    let errorNoTopicSelected = false;
    let dropDownCourses = [];
    let coursesItems;
    let courseIsSelected = false;
    let selectedCourseTitle = "";
    let selectedCourseId;
    let topicsItems;
    let courseHasUseTopics = false;
    let checkedKeepExistingTopic = false;
    let topicIsSelected = false;
    let selectedTopic = "";
    let selectedTopicId = 0; // IMPORTANT, if no topic is selected, then no topic is copied
    let copyWithoutTopic = false; // IMPORTANT, if the option "Without topic" is selected, then the plans will be copied without topic
    let checkedCopyDates = false;
    let checkedConnectedResources = true; // IMPORTANT, checked by default
    let checkedAssessmentCriteria = false;
    let showCopyInProgress = false;
    let confirmModalBody;
    let confirmModalBannerVisibility = false;
    let confirmModalBannerText;
    let showDisableElementsConfirmModal = false;
    let selectedPlanIdsToMakeNotVisible;

    const isSelfPaceCourse =
        config.columnVisibilities[columnTypes.Date] === columnVisibility.Nobody;
    const canConnectToCalendar = config.hasFunctionalityCalendar;

    $: coursesDropdownText = !courseIsSelected ? i18n.selectACourse : selectedCourseTitle;

    $: topicsDropdownText = !topicIsSelected ? i18n.selectATopic : selectedTopic;

    $: showAssessmentCriteriaSection =
        config.showAssessmentCriteriaSection &&
        selectedCourseId !== null &&
        selectedCourseId !== config.courseId;

    async function selectedAction(event) {
        const planIds = get(selectedPlanIds);
        const actionData = event.detail.data;

        switch (actionData.source) {
            case plansActionType.SetDate:
                if (!areAllPlansSelected && planIds && planIds.size === 0) {
                    showNoPlansSelectedModal = true;
                } else {
                    showSetDatesModal = true;
                }

                break;
            case plansActionType.ConnectToCalendar:
                if (planIds && planIds.size === 0) {
                    showNoPlansSelectedModal = true;
                } else {
                    showConnectToCalendarModal = true;
                }

                break;
            case plansActionType.Copy:
                if (!areAllPlansSelected && planIds && planIds.size === 0) {
                    showNoPlansSelectedModal = true;
                } else {
                    showCopyPlansModal = true;
                    dropDownCourses = await getCoursesForCopy();
                    setTimeout(() => openModalInMobile(), 100);
                }

                break;
            case plansActionType.Delete:
                if (!areAllPlansSelected && planIds && planIds.size === 0) {
                    showNoPlansSelectedModal = true;
                } else {
                    showDeletePlansConfirmModal = true;
                }

                break;
            case plansActionType.MakeVisible:
                if (!areAllPlansSelected && planIds && planIds.size === 0) {
                    showNoPlansSelectedModal = true;
                } else {
                    makeVisible();
                }

                break;
            case plansActionType.MakeNotVisible:
                if (!areAllPlansSelected && planIds && planIds.size === 0) {
                    showNoPlansSelectedModal = true;
                } else {
                    makeNotVisible();
                }

                break;
            case plansActionType.Print:
                if ((!areAllPlansSelected && !planIds) || planIds.size === 0) {
                    showNoPlansSelectedModal = true;
                } else {
                    window.open(
                        selectedView === plannerViews.tableView
                            ? areAllPlansSelected
                                ? endpoints.printAllPlansTbl(
                                      config.courseId,
                                      topicFilterIds,
                                      selectedView
                                  )
                                : endpoints.printPlansTbl(
                                      config.courseId,
                                      [...planIds],
                                      selectedView
                                  )
                            : printAsTopic
                              ? endpoints.printTopics(
                                    config.courseId,
                                    [config.topicId],
                                    [...planIds]
                                )
                              : endpoints.printPlans(config.courseId, [...planIds], selectedView)
                    );
                }

                break;
        }
    }

    /** Plan action options  **/
    function getActions() {
        const options = [
            {
                icon: "",
                label: i18n.makeVisible,
                link: false,
                target: "_self",
                value: { source: plansActionType.MakeVisible }
            },
            {
                icon: "",
                label: i18n.makeNotVisible,
                link: false,
                target: "_self",
                value: { source: plansActionType.MakeNotVisible }
            },
            ...(!isSelfPaceCourse
                ? [
                      {
                          icon: "",
                          label: i18n.setDate,
                          link: false,
                          target: "_self",
                          value: { source: plansActionType.SetDate }
                      }
                  ]
                : []),
            ...(canConnectToCalendar && !isSelfPaceCourse
                ? [
                      {
                          icon: "",
                          label: i18n.connectToCalendar,
                          link: false,
                          target: "_self",
                          value: { source: plansActionType.ConnectToCalendar }
                      }
                  ]
                : []),
            {
                icon: "",
                label: i18n.copy,
                link: false,
                target: "_self",
                value: { source: plansActionType.Copy }
            },
            {
                icon: "",
                label: i18n.print,
                link: false,
                target: "_self",
                value: { source: plansActionType.Print }
            },
            {
                icon: "",
                label: i18n.delete,
                link: false,
                target: "_self",
                value: { source: plansActionType.Delete }
            }
        ];

        return [{ options: options }];
    }

    function loadCourses() {
        const starredCoursesList = [];
        const otherCoursesList = [];

        // Selects the arrays for starred and other courses, indicates which courses are archived
        dropDownCourses.forEach(course => {
            if (course.HasCourseSettingPlanner) {
                const courseTitle = !course.Status
                    ? course.CourseTitle
                    : course.CourseTitle + " (" + i18n.archived + ")";

                if (course.IsFavourite) {
                    starredCoursesList.push({
                        icon: "",
                        label: courseTitle,
                        link: false,
                        target: "_self",
                        value: {
                            source: plansCopyActionModal.SelectCourse,
                            title: courseTitle,
                            id: course.CourseId,
                            hasUseTopics: course.HasCourseSettingUseTopics
                        }
                    });
                } else {
                    otherCoursesList.push({
                        icon: "",
                        label: courseTitle,
                        link: false,
                        target: "_self",
                        value: {
                            source: plansCopyActionModal.SelectCourse,
                            title: courseTitle,
                            id: course.CourseId,
                            hasUseTopics: course.HasCourseSettingUseTopics
                        }
                    });
                }
            }
        });

        /** Courses dropdown options  **/
        coursesItems = [
            {
                label: i18n.starred,
                options: starredCoursesList
            },
            {
                label: i18n.otherCourses,
                options: otherCoursesList
            }
        ];
    }

    function selectCourse(event) {
        const actionData = event.detail.data;

        if (actionData.source === plansCopyActionModal.SelectCourse) {
            errorNoCourseSelected = false;
            errorNoTopicSelected = false;
            courseIsSelected = true;
            selectedCourseTitle = actionData.title;
            selectedCourseId = actionData.id;
            courseHasUseTopics = false;
            topicIsSelected = false;
            selectedTopicId = 0; // Without topic

            loadTopics(actionData.id, actionData.hasUseTopics);
        }
    }

    async function loadTopics(courseId, hasUseTopics) {
        const topicsList = [];
        const topics = await getTopicsForCopyPlans(courseId);

        if (hasUseTopics) courseHasUseTopics = true;

        topics.forEach(topic => {
            topicsList.push({
                icon: "",
                label: topic.Name,
                link: false,
                target: "_self",
                value: {
                    source: plansCopyActionModal.SelectTopic,
                    name: topic.Name,
                    id: topic.TopicId
                }
            });
        });

        topicsList.push({
            // Option to choose to copy the plan without topic
            icon: "",
            label: config.withoutTopic,
            link: false,
            target: "_self",
            value: {
                source: plansCopyActionModal.SelectWithoutTopic,
                name: config.withoutTopic,
                id: 0
            }
        });

        /** Topics dropdown options  **/
        topicsItems = [
            {
                options: topicsList
            }
        ];
    }

    function selectTopic(event) {
        const actionData = event.detail.data;

        switch (actionData.source) {
            case plansCopyActionModal.SelectTopic:
                errorNoTopicSelected = false;
                topicIsSelected = true;
                selectedTopic = actionData.name;
                selectedTopicId = actionData.id;
                copyWithoutTopic = false;
                break;
            case plansCopyActionModal.SelectWithoutTopic:
                errorNoTopicSelected = false;
                topicIsSelected = true;
                selectedTopic = actionData.name;
                selectedTopicId = actionData.id;
                copyWithoutTopic = true;
                break;
            default:
                break;
        }
    }

    function updateSelectATopicSection() {
        // If "Keep existing" is checked/unchecked, then we reset the entire dropdown
        topicIsSelected = false;
        selectedTopic = "";
        selectedTopicId = 0;
        copyWithoutTopic = false;

        if (checkedKeepExistingTopic) {
            checkedKeepExistingTopic = false;
        } else {
            checkedKeepExistingTopic = true;
            errorNoTopicSelected = false;
        }
    }

    function updateCheckedStatusCopyDates() {
        if (checkedCopyDates) {
            checkedCopyDates = false;
        } else {
            checkedCopyDates = true;
        }
    }

    function updateCheckedStatusConnectedResources() {
        if (checkedConnectedResources) {
            checkedConnectedResources = false;
        } else {
            checkedConnectedResources = true;
        }
    }

    function updateCheckedStatusAssessmentCriteria() {
        if (checkedAssessmentCriteria) {
            checkedAssessmentCriteria = false;
        } else {
            checkedAssessmentCriteria = true;
        }
    }

    async function copyPlansModalClicked(event) {
        if (event.detail.confirmed === true) {
            if (!courseIsSelected) {
                // If Copy is pressed, but no course is selected
                showCopyPlansModal = true;
                errorNoCourseSelected = true;
            } else if (courseHasUseTopics && !checkedKeepExistingTopic && !topicIsSelected) {
                // If Copy is pressed, but "Keep existing is not checked" and no topic is selected
                showCopyPlansModal = true;
                errorNoTopicSelected = true;
            } else {
                showCopyInProgress = true;

                const planIds = get(selectedPlanIds);

                if (planIds && planIds.size > 0) {
                    const copyPlansData = await copyPlansFromCourse(
                        selectedCourseId,
                        selectedTopicId,
                        checkedCopyDates,
                        checkedConnectedResources,
                        checkedAssessmentCriteria,
                        [...planIds],
                        copyWithoutTopic,
                        areAllPlansSelected,
                        topicFilterIds
                    );

                    confirmModalBody = copyPlansData.ResultMessage;

                    if (copyPlansData.ResultWarningNotes) {
                        // If there is a warning note, then it is displayed in the banner
                        confirmModalBannerVisibility = true;
                        confirmModalBannerText = copyPlansData.ResultWarningNotes;
                    }
                }

                showCopyInProgress = false;
                showCopyConfirmModal = true;
            }
        } else {
            // On Cancel, the Plans page gets reloaded, to reset all parameters
            completePlanAction();
        }
    }

    function closeCopyPlansConfirmModalClicked() {
        showCopyConfirmModal = false;
        completePlanAction();
    }

    async function deletePlansModalClicked({ detail: { confirmed: isPlansDeleteConfirmed } }) {
        if (isPlansDeleteConfirmed) {
            if (areAllPlansSelected) {
                await removePlansFromCourse([], true, topicFilterIds);
            } else {
                const planIds = get(selectedPlanIds);

                if (planIds.size > 0) {
                    await removePlansFromCourse([...planIds]);
                }
            }

            completePlanAction();
        }

        showDeletePlansConfirmModal = false;
    }

    async function saveSetDatesModalClicked({ detail }) {
        const planIds = get(selectedPlanIds);

        if (areAllPlansSelected || planIds.size > 0) {
            await updatePlansDates(
                [...planIds],
                detail.start,
                detail.stop,
                detail.weekNumber,
                detail.weekDaysAPI,
                getPlansOrderFromPlansView(selectedView),
                areAllPlansSelected,
                topicFilterIds
            );
            completePlanAction();
        }
    }

    async function saveConnectToCalendarModalClicked({ detail: { activityIds } }) {
        if (activityIds.length > 0) {
            if (areAllPlansSelected) {
                await updatePlansDatesEvents([], activityIds, true, topicFilterIds);
            } else {
                const planIds = get(selectedPlanIds);

                if (planIds.size > 0) {
                    await updatePlansDatesEvents([...planIds], activityIds);
                }
            }

            completePlanAction();
        }
    }

    // On Cancel, the Plans page gets reloaded, to reset all parameters
    function closeSetDatesModalClicked() {
        completePlanAction();
    }

    function noPlansModalClicked() {
        showNoPlansSelectedModal = false;
    }

    // Make visible only the selected plans that are not visible, ignore the rest.
    async function makeVisible() {
        if (areAllPlansSelected) {
            await setPlansActive(
                [],
                true,
                config.activateElementsByPlan,
                true,
                areAllPlansSelected,
                topicFilterIds
            );
            await tick();
            completePlanAction();
        } else {
            const planIds = get(selectedPlanIds);

            // From the list of plans we extract the selected ones which are NOT visible
            const plansNotVisible_Selected = data.plans
                ? data.plans.filter(p => !p.isActive && planIds.has(p.id))
                : [];

            if (plansNotVisible_Selected.length !== 0) {
                if (plansNotVisible_Selected.length === 1) {
                    await setPlanActive(
                        plansNotVisible_Selected[0].id,
                        true,
                        config.activateElementsByPlan
                    );
                } else if (plansNotVisible_Selected.length > 1) {
                    const planIds = [];

                    plansNotVisible_Selected.forEach(plan => {
                        planIds.push(plan.id);
                    });
                    await setPlansActive(planIds, true, config.activateElementsByPlan);
                }

                await tick();
            }

            completePlanAction();
        }
    }

    // Make not visible only the selected plans that are visible, ignore the rest.
    async function makeNotVisible() {
        if (areAllPlansSelected) {
            // If the planner setting 'Activate connected elements when the plan is activated' is enabled and the selected plans have connected elements, a confim dialog will appear
            if (config.activateElementsByPlan) {
                showDisableElementsConfirmModal = true;
            } else {
                await setPlansActive(
                    [],
                    false,
                    config.activateElementsByPlan,
                    true,
                    areAllPlansSelected,
                    topicFilterIds
                );
                await tick();
                completePlanAction();
            }
        } else {
            const planIds = get(selectedPlanIds);

            // From the list of plans we extract the selected ones which are visible
            const plansVisible_Selected = data.plans
                ? data.plans.filter(p => p.isActive && planIds.has(p.id))
                : [];
            const plansVisible_Selected_WithElements = plansVisible_Selected.filter(
                p => p.elements.length >= 1
            );

            if (plansVisible_Selected.length !== 0) {
                // If the planner setting 'Activate connected elements when the plan is activated' is enabled and the selected plans have connected elements, a confim dialog will appear
                if (
                    config.activateElementsByPlan &&
                    plansVisible_Selected_WithElements.length !== 0
                ) {
                    selectedPlanIdsToMakeNotVisible = plansVisible_Selected;
                    showDisableElementsConfirmModal = true;
                } else {
                    if (plansVisible_Selected.length === 1) {
                        await setPlanActive(
                            plansVisible_Selected[0].id,
                            false,
                            config.activateElementsByPlan
                        );
                    } else if (plansVisible_Selected.length > 1) {
                        const planIds = [];

                        plansVisible_Selected.forEach(plan => {
                            planIds.push(plan.id);
                        });
                        await setPlansActive(planIds, false, config.activateElementsByPlan);
                    }

                    await tick();
                    completePlanAction();
                }
            } else {
                completePlanAction();
            }
        }
    }

    // A confirmation modal appears when plans are deactivated
    // "Do you want to deactivate all elements connected to this plan?"
    async function confirmModalClicked(event) {
        const toggleElementsActivation = event.detail.confirmed;

        if (areAllPlansSelected) {
            await setPlansActive(
                [],
                false,
                toggleElementsActivation,
                true,
                areAllPlansSelected,
                topicFilterIds
            );
        } else if (selectedPlanIdsToMakeNotVisible.length === 1) {
            await setPlanActive(
                selectedPlanIdsToMakeNotVisible[0].id,
                false,
                toggleElementsActivation
            );
        } else if (selectedPlanIdsToMakeNotVisible.length > 1) {
            const planIds = [];

            selectedPlanIdsToMakeNotVisible.forEach(plan => {
                planIds.push(plan.id);
            });
            await setPlansActive(planIds, false, toggleElementsActivation);
        }

        showDisableElementsConfirmModal = false;
        completePlanAction();
    }

    const modalId = "#show-copy-plans-modal-id";

    function openModalInMobile() {
        if (isMobile()) {
            if (document.querySelector(modalId) !== null) {
                document.querySelector(modalId).style.minHeight = "100%";
            }
        }
    }

    window.addEventListener("orientationchange", function () {
        if (document.querySelector(modalId) !== null) {
            setTimeout(() => openModalInMobile(), 300);
        }
    });

    const dropdownCopyPlanSection =
        "#copy-plans-modal__courses-dropdown > section > section.prom-dropdown__body";
    const dropdownCopyTopicSection =
        "#copy-plans-modal__topics-dropdown > section > section.prom-dropdown__body";
    const dropdownHeight = "150px";

    // Add a scroll bar to the courses dropdown in copy plan modal
    function addScrollCourseDropdown() {
        if (document.querySelector(dropdownCopyPlanSection).style.height === "") {
            document.querySelector(dropdownCopyPlanSection).style.height = dropdownHeight;
        }
    }

    // Add a scroll bar to the topics dropdown in copy plan modal
    function addScrollTopicDropdown() {
        if (document.querySelector(dropdownCopyTopicSection).style.height === "") {
            document.querySelector(dropdownCopyTopicSection).style.height = dropdownHeight;
        }
    }

    const completePlanAction = () => {
        selectedPlanIds.set(new Set([]));
        dispatch("endOfActionOnSelectedPlans");
    };
</script>
