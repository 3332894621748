import {
    getLearningObjectives,
    setPlanLearningObjectives,
    removePlanLearningObjective
} from "../api";

export async function loadLearningObjectives(parentId, selectableCategory = false) {
    const learningObjectiveResponse = await getLearningObjectives(parentId, { selectableCategory });

    return learningObjectiveResponse.Items.map(lo => {
        const intId = parseInt(lo.Id);

        // The items returned from this endpoint have string IDs but the
        // learning objectives' ids are actually ints and need to be sent
        // to the API and compared as integers
        if (!Number.isNaN(intId)) {
            lo.Id = intId;
        }

        return {
            id: lo.Id,
            title: lo.Title,
            description: lo.Description,
            selectable: lo.Selectable,
            selectedByDefault: lo.SelectedByDefault,
            setRootAsParent: lo.SetRootAsParent,
            isFolder: lo.IsFolder,
            cssClass: lo.CssClass,
            parentId
        };
    });
}

export function updateLearningObjectives(planId, learningObjectiveIds) {
    return setPlanLearningObjectives(planId, learningObjectiveIds);
}

export function removeLearningObjective(planId, learningObjectiveId) {
    return removePlanLearningObjective(planId, learningObjectiveId);
}
