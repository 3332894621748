import { writable } from "svelte/store";

const defaultPlanCounts = {
    planCounts: {
        currentPlansCount: 0,
        pastPlansCount: 0,
        withoutDatePlansCount: 0,
        topicsCount: 0,
        allPlansCount: 0
    },
    loading: true
};

const defaultPlansData = {
    plans: [],
    total: 0,
    currentPageIndex: 0,
    loading: true
};

const defaultTopicsData = {
    topics: [],
    loading: true
};

export const currentPlansData = writable(defaultPlansData);

export const withoutDatePlansData = writable(defaultPlansData);

export const pastPlansData = writable(defaultPlansData);

export const topicsData = writable(defaultTopicsData);

export const selectedPlanIds = writable(new Set([]));

export const selectedTopicIds = writable(new Set([]));

export const allPlanCountsData = writable(defaultPlanCounts);

export const allPlansData = writable(defaultPlansData);
