import { writable } from "svelte/store";

/** @type {import("svelte/store").Writable<any>} */
export const planData = writable({});

export const backLink = writable({ url: "", label: "" });

const defaultPlansData = {
    plans: [],
    total: 0,
    currentPageIndex: 0,
    loading: true
};

const createPlanFilesStatusStore = () => {
    const { subscribe, update } = writable(new Map());

    const updateFileStatus = (file, planId, columnId) =>
        update(files => {
            files.set(file.name, { ...file, planId, columnId });

            return files;
        });

    const dismissError = fileName =>
        update(files => {
            files.delete(fileName);

            return files;
        });

    return {
        subscribe,
        updateFileStatus,
        dismissError
    };
};

const createPlansStore = () => {
    const plansStore = writable(defaultPlansData);

    return {
        ...plansStore,
        reset: () => plansStore.set(defaultPlansData)
    };
};

export const planFilesStatus = createPlanFilesStatusStore();

export const topicCurrentPlansData = createPlansStore();

export const topicWithoutDatePlansData = createPlansStore();

export const topicPastPlansData = createPlansStore();

export const topicAllPlansData = createPlansStore();
