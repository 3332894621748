import { plannerViews, plansOrder } from "../constants";

/**
 * Gets planner view by selected tab index
 * @param {number} tabIndex
 */
export function getPlannerView(tabIndex) {
    switch (tabIndex) {
        case 0:
            return plannerViews.current;
        case 1:
            return plannerViews.past;
        case 2:
            return plannerViews.withoutDate;
        default:
            return plannerViews.notDefined;
    }
}

export function getPlansOrderFromPlansView(plansView) {
    switch (plansView) {
        case plannerViews.topic: {
            return plansOrder.topic;
        }

        case plannerViews.tableView: {
            return plansOrder.tableView;
        }

        default: {
            return plansOrder.default;
        }
    }
}
