import config from "../config";
import { columnTypes, columnVisibility } from "../constants";

export function isDateVisible(columnVisibilities = undefined, canEdit = undefined) {
    return isPlanColumnVisible(columnTypes.Date, true, columnVisibilities, canEdit);
}

export function isClassHoursVisible(classHours) {
    const isValueViewableForAll = classHours != 0;

    return isPlanColumnVisible(columnTypes.ClassHours, isValueViewableForAll, undefined, undefined);
}

export function getInvalidClassHoursMessageVisibility(classHours) {
    return classHours < 0 || classHours > 100 || !/^[0-9]+$/.test(classHours);
}

function isPlanColumnVisible(
    columnType,
    isValueViewableForAll,
    columnVisibilities = undefined,
    canEdit = undefined
) {
    let planColumnVisibility = config.columnVisibilities
        ? config.columnVisibilities[columnType]
        : undefined;

    if (
        !config.columnVisibilities ||
        planColumnVisibility === undefined ||
        config.canEdit === undefined
    ) {
        // Check if calendar
        if (columnVisibilities) {
            planColumnVisibility = columnVisibilities[columnType];

            // Check if teacher
            return canEdit
                ? planColumnVisibility !== columnVisibility.Nobody
                : planColumnVisibility === columnVisibility.All && isValueViewableForAll;
        } else {
            return true;
        }
    }

    // Check if teacher
    return config.canEdit
        ? planColumnVisibility !== columnVisibility.Nobody
        : planColumnVisibility === columnVisibility.All && isValueViewableForAll;
}
