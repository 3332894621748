﻿const CommonFunctions = {
    isEdge: () => {
        // This is not used anymore - Edge here was old Edge not supported anymore
        return false;
    },
    makePostMessageCall: (parentFrame, data) => {
        parentFrame.postMessage(JSON.stringify(data), "*");
    },
    attachPostMessageEventListener: (window, messageNames, callbackFunction) => {
        window.addEventListener("message", function (e) {
            let data;

            const originWindow = e.source;

            try {
                if (
                    e.data &&
                    (data = JSON.parse(e.data)) &&
                    data.messageName &&
                    (typeof messageNames === "string"
                        ? data.messageName === messageNames
                        : messageNames.includes(data.messageName))
                ) {
                    callbackFunction(data, originWindow);
                }
            } catch (e) {
                // do nothing
            }
        });
    },
    safeAjax: settings => {
        const data = settings.data !== undefined && settings.data !== null ? settings.data : {};
        const method = settings.type ? settings.type : "GET";

        return fetch(settings.url, {
            method: method,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    },
    safeAjaxPost: settings => {
        // eslint-disable-next-line no-undef
        const postSettings = $.extend({}, settings, { type: "POST" });

        return CommonFunctions.safeAjax(postSettings);
    },
    measureKpi: (url, section, measurement, context) => {
        if (context === void 0) {
            context = null;
        }

        try {
            return CommonFunctions.safeAjaxPost({
                url: url,
                data: {
                    measureSection: section,
                    measurement: measurement,
                    measureContext: context
                }
            });
        } catch (e) {
            // do nothing
        }
    }
};

window.CommonFunctions = CommonFunctions;

const CommonConstants = {
    keyCodes: {
        escape: 27
    }
};

window.CommonConstants = CommonConstants;

const MessageNames = {
    textEditorInteraction: "textEditorInteraction",
    editEmbededContent: "editEmbededContent"
};

window.MessageNames = MessageNames;

const __extends =
    (this && this.__extends) ||
    function (d, b) {
        // eslint-disable-next-line no-prototype-builtins
        for (const p in b) if (b.hasOwnProperty(p)) d[p] = b[p];

        function __() {
            this.constructor = d;
        }

        d.prototype = b === null ? Object.create(b) : ((__.prototype = b.prototype), new __());
    };

const CommonWindowMessages = new Object();

CommonWindowMessages.MessageNames = MessageNames;

const GenericMessage = (function () {
    function GenericMessage(messageName) {
        this.messageName = messageName;
    }

    return GenericMessage;
})();

CommonWindowMessages.GenericMessage = GenericMessage;
const GenericMessageWithData = (function (_super) {
    __extends(GenericMessageWithData, _super);

    function GenericMessageWithData(messageName, extendedData) {
        _super.call(this, messageName);
        this.extendedData = extendedData;
    }

    return GenericMessageWithData;
})(GenericMessage);

CommonWindowMessages.GenericMessageWithData = GenericMessageWithData;

const TextEditorBaseInteractionMessage = (function (_super) {
    __extends(TextEditorBaseInteractionMessage, _super);

    function TextEditorBaseInteractionMessage(
        interactionType,
        closeDialogAfterPerformingOperation,
        editorInstanceId
    ) {
        if (closeDialogAfterPerformingOperation === void 0) {
            closeDialogAfterPerformingOperation = false;
        }

        if (editorInstanceId === void 0) {
            editorInstanceId = "";
        }

        _super.call(this, MessageNames.textEditorInteraction);
        this.interactionType = interactionType;
        this.closeDialogAfterPerformingOperation = closeDialogAfterPerformingOperation;
        this.editorInstanceId = editorInstanceId;
    }

    return TextEditorBaseInteractionMessage;
})(GenericMessage);

/** Generic message with data for any text editor interactions. **/
const TextEditorBaseInteractionWithDataMessage = (function (_super) {
    __extends(TextEditorBaseInteractionWithDataMessage, _super);

    function TextEditorBaseInteractionWithDataMessage(
        interactionType,
        extendedData,
        closeDialogAfterPerformingOperation,
        editorInstanceId
    ) {
        if (closeDialogAfterPerformingOperation === void 0) {
            closeDialogAfterPerformingOperation = false;
        }

        if (editorInstanceId === void 0) {
            editorInstanceId = "";
        }

        _super.call(this, interactionType, closeDialogAfterPerformingOperation, editorInstanceId);
        this.extendedData = extendedData;
    }

    return TextEditorBaseInteractionWithDataMessage;
})(TextEditorBaseInteractionMessage);

/** Sent to text editor from inner frame to close dialog **/
const TextEditorCloseDialogMessage = (function (_super) {
    __extends(TextEditorCloseDialogMessage, _super);

    function TextEditorCloseDialogMessage() {
        _super.call(this, TextEditorInteractionType.CloseDialog, true);
    }

    return TextEditorCloseDialogMessage;
})(TextEditorBaseInteractionMessage);

CommonWindowMessages.TextEditorCloseDialogMessage = TextEditorCloseDialogMessage;

/** Sent to text editor from inner frame to insert given HTML text **/
const TextEditorInsertHtmlMessage = (function (_super) {
    __extends(TextEditorInsertHtmlMessage, _super);

    function TextEditorInsertHtmlMessage(
        htmlContent,
        closeDialogAfterPerformingOperation,
        editorInstanceId,
        isEmbeddedImage
    ) {
        if (closeDialogAfterPerformingOperation === void 0) {
            closeDialogAfterPerformingOperation = false;
        }

        if (editorInstanceId === void 0) {
            editorInstanceId = "";
        }

        if (isEmbeddedImage === void 0) {
            isEmbeddedImage = false;
        }

        _super.call(
            this,
            TextEditorInteractionType.InsertHtml,
            closeDialogAfterPerformingOperation
        );
        this.htmlContent = htmlContent;
        this.editorInstanceId = editorInstanceId;
        this.isEmbeddedImage = isEmbeddedImage;
    }

    return TextEditorInsertHtmlMessage;
})(TextEditorBaseInteractionMessage);

CommonWindowMessages.TextEditorInsertHtmlMessage = TextEditorInsertHtmlMessage;
/** Sent to text editor from inner frame to insert the HTML of hyperlink to file **/
const TextEditorInsertLinkToFileMessage = (function (_super) {
    __extends(TextEditorInsertLinkToFileMessage, _super);

    function TextEditorInsertLinkToFileMessage(
        linkUrl,
        linkName,
        closeDialogAfterPerformingOperation
    ) {
        if (closeDialogAfterPerformingOperation === void 0) {
            closeDialogAfterPerformingOperation = false;
        }

        _super.call(
            this,
            TextEditorInteractionType.InsertLinkToFile,
            closeDialogAfterPerformingOperation
        );
        this.linkUrl = linkUrl;
        this.linkName = linkName;
    }

    return TextEditorInsertLinkToFileMessage;
})(TextEditorBaseInteractionMessage);

CommonWindowMessages.TextEditorInsertLinkToFileMessage = TextEditorInsertLinkToFileMessage;
/** Sent to text editor from inner frame to гpdate list of plugins with deleted or added list items **/
const TextEditorUpdateItslPluginListMessage = (function (_super) {
    __extends(TextEditorUpdateItslPluginListMessage, _super);

    function TextEditorUpdateItslPluginListMessage(pluginId) {
        _super.call(this, TextEditorInteractionType.UpdateItslPluginsList, true);
        this.pluginId = pluginId;
    }

    return TextEditorUpdateItslPluginListMessage;
})(TextEditorBaseInteractionMessage);

CommonWindowMessages.TextEditorUpdateItslPluginListMessage = TextEditorUpdateItslPluginListMessage;
/** Send to ImageProperties dialog image settings **/
const TextEditorSendImageSettingsMessage = (function (_super) {
    __extends(TextEditorSendImageSettingsMessage, _super);

    function TextEditorSendImageSettingsMessage(extendedData) {
        _super.call(
            this,
            TextEditorInteractionType.SendImageSettingsToImagePropertiesDialog,
            extendedData
        );
    }

    return TextEditorSendImageSettingsMessage;
})(TextEditorBaseInteractionWithDataMessage);

CommonWindowMessages.TextEditorSendImageSettingsMessage = TextEditorSendImageSettingsMessage;
/** Insert image to slideshow **/
const TextEditorInsertImageToSlideshow = (function (_super) {
    __extends(TextEditorInsertImageToSlideshow, _super);

    function TextEditorInsertImageToSlideshow(extendedData) {
        _super.call(this, TextEditorInteractionType.InsertImageToSlideshow, extendedData, true);
    }

    return TextEditorInsertImageToSlideshow;
})(TextEditorBaseInteractionWithDataMessage);

CommonWindowMessages.TextEditorInsertImageToSlideshow = TextEditorInsertImageToSlideshow;
/** Inserts the HTML of hyperlink regarding selected text **/
const TextEditorInsertTextLink = (function (_super) {
    __extends(TextEditorInsertTextLink, _super);

    function TextEditorInsertTextLink(
        url,
        text,
        target,
        additionalAttributes,
        closeDialogAfterPerformingOperation
    ) {
        if (closeDialogAfterPerformingOperation === void 0) {
            closeDialogAfterPerformingOperation = false;
        }

        _super.call(
            this,
            TextEditorInteractionType.InsertTextLink,
            closeDialogAfterPerformingOperation
        );
        this.linkUrl = url;
        this.linkText = text;
        this.linkTarget = target;
        this.linkAdditionalAttributes = additionalAttributes;
    }

    return TextEditorInsertTextLink;
})(TextEditorBaseInteractionMessage);

CommonWindowMessages.TextEditorInsertTextLink = TextEditorInsertTextLink;
/** Scales current dialog. **/
// eslint-disable-next-line no-unused-vars
const TextEditorScaleDialog = (function (_super) {
    __extends(TextEditorScaleDialog, _super);

    function TextEditorScaleDialog() {
        _super.call(this, TextEditorInteractionType.ScaleDialog, true);
    }

    return TextEditorScaleDialog;
})(TextEditorBaseInteractionMessage);

/** Defines the list of possible text editor interactions via postMessage **/
(function (TextEditorInteractionType) {
    /** Closes current dialog. **/
    TextEditorInteractionType[(TextEditorInteractionType["CloseDialog"] = 0)] = "CloseDialog";
    /** Inserts given HTML string into editor **/
    TextEditorInteractionType[(TextEditorInteractionType["InsertHtml"] = 1)] = "InsertHtml";
    /** Inserts the HTML of hyperlink to file **/
    TextEditorInteractionType[(TextEditorInteractionType["InsertLinkToFile"] = 2)] =
        "InsertLinkToFile";
    /** Updates list of plugins with deleted or added list items**/
    TextEditorInteractionType[(TextEditorInteractionType["UpdateItslPluginsList"] = 3)] =
        "UpdateItslPluginsList";
    /** Send image settings to opened image properties dialog **/
    TextEditorInteractionType[
        (TextEditorInteractionType["SendImageSettingsToImagePropertiesDialog"] = 4)
    ] = "SendImageSettingsToImagePropertiesDialog";
    /** Insert image to slideshow **/
    TextEditorInteractionType[(TextEditorInteractionType["InsertImageToSlideshow"] = 5)] =
        "InsertImageToSlideshow";
    /** Inserts the HTML of hyperlink regarding selected text **/
    TextEditorInteractionType[(TextEditorInteractionType["InsertTextLink"] = 6)] = "InsertTextLink";
    /** Scales current dialog. **/
    TextEditorInteractionType[(TextEditorInteractionType["ScaleDialog"] = 7)] = "ScaleDialog";
})(
    CommonWindowMessages.TextEditorInteractionType ||
        (CommonWindowMessages.TextEditorInteractionType = {})
);

const TextEditorInteractionType = CommonWindowMessages.TextEditorInteractionType;

window.CommonWindowMessages = CommonWindowMessages;

if (!String.prototype.setQueryStringParameter) {
    String.prototype.setQueryStringParameter = function (name, value) {
        const url = this;

        if (value) {
            if (url.indexOf(name + "=") > -1) {
                // Modify param value in the URL
                return url.replace(
                    new RegExp(name + "=[^&]*(&|$)"),
                    name + "=" + encodeURIComponent(value) + "&"
                );
            } else {
                // Insert the param into the URL
                return (
                    url.slice(0, url.indexOf("?") + 1) +
                    name +
                    "=" +
                    encodeURIComponent(value) +
                    "&" +
                    url.slice(url.indexOf("?") + 1)
                );
            }
        } else if (url.indexOf(name + "=") > -1) {
            // If there's param in the url, remove it
            return url.replace(new RegExp(name + "=[^&]*(&|$)"), "");
        }

        return url.toString();
    };
}
