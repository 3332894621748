import {
    setTopicLearningObjectives,
    removeTopicLearningObjective,
    setCustomFieldText
} from "../api";
import { topicData } from "../logic/topicPage/stores";

export async function updateTopicFieldLearningObjectives(topicId, columnId, learningObjectives) {
    const loIds = learningObjectives.map(lo => lo.id);

    return setTopicLearningObjectives(topicId, columnId, loIds);
}

export async function removeTopicFieldLearningObjective(topicId, columnId, learningObjectiveId) {
    return removeTopicLearningObjective(topicId, columnId, learningObjectiveId);
}

export async function updateTopicFieldText(topicId, columnId, text) {
    const result = await setCustomFieldText(topicId, columnId, text);

    // Use the resulting safeHtml to set display text
    topicData.update(data => {
        const fieldIndex = data.topic.customFields.findIndex(f => f.id === columnId);

        if (fieldIndex !== -1) {
            data.topic.customFields[fieldIndex].displayText = result.SafeHtml;
            data.topic.customFields[fieldIndex].isPlainText = result.CustomData.isPlainText;
        }

        return data;
    });

    return result;
}
