<span {hourId} {hourLabel} {minuteId} {isDisabled} class="time-select-section">
    {#if config.useTwelveHourFormat}
        <span>
            <Select label={hourLabel} bind:value={hours} disabled={isDisabled}>
                {#each twelvehoursArray as hour}
                    <option value={hour}>
                        {hour}
                    </option>
                {/each}
            </Select>
        </span>
    {:else}
        <span>
            <Select label={hourLabel} id={hourId} bind:value={hours} disabled={isDisabled}>
                {#each hoursArray as hour}
                    <option value={hour}>
                        {hour}
                    </option>
                {/each}
            </Select>
        </span>
    {/if}
    <span>
        <Select label={minuteLabel} id={minuteId} bind:value={minutes} disabled={isDisabled}>
            {#each minutesArray as minute}
                <option value={minute}>
                    {minute}
                </option>
            {/each}
        </Select>
    </span>
    {#if config.useTwelveHourFormat}
        <span class="time-select-section__period">
            <Select label={periodLabel} id={periodId} bind:value={isPMstr} disabled={isDisabled}>
                <option value="false"> AM </option>
                <option value="true"> PM </option>
            </Select>
        </span>
    {/if}
</span>

<script>
    import Select from "@itslearning/prometheus/assets/inputs/Select/v1/Select.svelte";

    const hoursArray = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23"
    ];
    const twelvehoursArray = [
        "12",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11"
    ];
    const minutesArray = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];

    export let hourId;
    export let hourLabel;
    export let minuteId;
    export let minuteLabel;
    export let periodId;
    export let periodLabel;
    export let hours;
    export let minutes;
    export let isPM;
    export let isDisabled;
    export let config;

    let isPMstr = isPM ? "true" : "false"; // For the first load, the boolean received is converted to string.

    $: {
        // This is needed as the value in the Select component cannot accept booleans (see prometheus rep.).
        if (isPMstr === "true") {
            isPM = true;
        } else {
            isPM = false;
        }
    }
</script>
