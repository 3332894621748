<svelte:window
    on:scroll={onScroll}
    on:mousemove={pointerMove}
    on:mouseup={dragEnd}
    on:touchend={dragEnd}
    on:touchmove={pointerMove}
/>

{#if data.plans.length < data.total}
    <Switch
        id="its-plans__autoloading-id"
        name="its-plans__autoloading-toggle"
        label={i18n.toggleAutoloadingOfPlans}
        bind:checked={isAutoloadingOn}
        on:click={toggleAutoloading}
    />
{/if}

<Modal
    id="show-replace-sorting-confirm-modal-id"
    bind:enabled={showReplaceSortingConfirmModal}
    titleText={i18n.replaceSortingDialog_Heading}
    bodyHtml={i18n.replaceSortingDialog_Text}
    confirmText={i18n.replace}
    cancelText={i18n.cancel}
    confirmButtonType="destructive"
    on:close={replaceSortingModalClicked}
/>

<section class="its-plans__list-section" bind:this={listSection}>
    <ul
        class="its-plans__list"
        tabindex="-1"
        aria-labelledby="plans"
        bind:this={listElement}
        role={keyboardReorder ? "listbox" : undefined}
        aria-activedescendant={keyboardReorderActiveId
            ? `itsl-plan-card-${keyboardReorderActiveId}`
            : undefined}
        on:keydown={listKeyDown}
        on:blur={listBlur}
    >
        {#each mainPlans as plan (plan.id)}
            <PlanCard
                {plan}
                showSelect={config.canEdit}
                locale={config.locale}
                showDrag={config.canEdit &&
                    !isMobile() &&
                    (isDraggable ||
                        (config.enableTopicPlansOwnSortingFixedOption && plan.topic === null)) &&
                    data.plans.length > 1}
                on:dragStart={e => dragStart(e.detail, plan)}
                on:keyboardDragStart={() => keyboardDragStart(plan)}
                keyboardReorderSelected={keyboardReorder && keyboardReorderActiveId === plan.id}
                keyboardReorderActive={keyboardReorder}
                dragAndDropSelected={dragSelectedPlan && plan.id === dragSelectedPlan.id}
                canEdit={config.canEdit}
                topicName={config.topicName}
                isLearner={config.isLearner}
                {selectedPlanIds}
            ></PlanCard>
        {/each}
    </ul>
    {#if dragSelectedPlan}
        <div class="its-plans__plan-dragging" style={draggedElementStyle}>
            <PlanCard
                plan={dragSelectedPlan}
                color={dragSelectedPlan.borderColor}
                showSelect={true}
                locale={config.locale}
                showDrag={true}
                canEdit={config.canEdit}
                topicName={config.topicName}
                isLearner={config.isLearner}
                {selectedPlanIds}
            />
        </div>
    {/if}
</section>

{#if upcomingPlans.length}
    <div class="its-plans__upcoming-plans-divider">
        <h3>{i18n.upcomingPlans}</h3>
    </div>

    <ul class="its-plans__list">
        {#each upcomingPlans as plan (plan.id)}
            <li>
                <PlanCard
                    {plan}
                    {i18n}
                    showSelect={config.canEdit}
                    locale={config.locale}
                    showDrag={config.canEdit && isDraggable}
                    canEdit={config.canEdit}
                    topicName={config.topicName}
                    isLearner={config.isLearner}
                    {selectedPlanIds}
                ></PlanCard>
            </li>
        {/each}
    </ul>
{/if}

<div aria-live="polite" aria-atomic="true">
    {#if showLoading}
        <div class="its-plans__loading" aria-busy={data.loading}>
            <span class="screen-reader">{i18n.loading}</span>
        </div>
    {/if}
</div>

{#if (!data.loading || !isAutoloadingOn) && data.plans.length < data.total}
    <div class="its-plans__show-more">
        {#if loadingError}
            <span class="its-plans__show-more-error">{i18n.friendlyUnknownError}</span>
        {/if}
        <Button type="plain" htmlType="button" text={i18n.showMore} on:click={showMore} />
    </div>
{/if}

<script>
    import PlanCard from "../PlanCard/PlanCard.svelte";
    import i18n from "../../i18n";
    import config from "../../config";
    import Button from "@itslearning/prometheus/assets/inputs/Button/v1/Button.svelte";
    import Switch from "@itslearning/prometheus/assets/inputs/Switch/v1/Switch.svelte";
    import Modal from "@itslearning/prometheus/assets/modals/Modal/v2/Modal.svelte";
    import {
        dragAndDropPlanInLesson,
        setPlanOrderInTopic,
        getPlanOrderInTopic,
        dragAndDropPlanInTopic
    } from "../../api";
    import { loadAllPlans, loadWithoutDatePlans } from "../../logic/plans/loadDataController";
    import { normalizeKey } from "@itslearning/atlas/keyboard/normalize";
    import { Keys } from "@itslearning/atlas/keyboard/keys";
    import { debounce } from "@itslearning/atlas/dom/Ratecontrol";
    import { onMount, createEventDispatcher } from "svelte";
    import { isMobile } from "@itslearning/atlas/detectors/UserAgentHelper";
    import { loadPlansForTopic } from "../../helpers/loadPlansHelper";
    import { plansData } from "../../logic/topicPage/stores";
    import { planSorting } from "../../constants";

    export let selectedPlanIds = {
        set: () => {}
    };

    const upcomingSplitTime = new Date(config.endOfCurrentDay).getTime();
    const dispatch = createEventDispatcher();

    export let separateUpcomingPlans = false;

    export let data;

    export let loadMethod;

    export let drag;

    let showLoading;
    let scrollBottom;
    let loadingError;
    let orderDirty = false;
    let keyboardReorder = false;
    let keyboardReorderActiveId;
    let reorderDragHandleId;
    let draggedElementTop;
    let dragSelectedPlan = undefined;
    let sourceSelectedPlan = undefined;
    let targetSelectedPlan = undefined;
    let targetSelectedPlanAfter = false;
    let debounceSavePlanOrder;
    let listSection;
    let listElement;
    let dragScrolling = false;
    let newPlanUpdatePerformed = false;
    let isAutoloadingOn = true;

    const isDraggable = loadMethod === loadWithoutDatePlans || loadMethod === loadAllPlans || drag;

    let plansForTopicHasBeenReordered = false;
    let showReplaceSortingConfirmModal = false;
    let dragConfirmed = false;
    let currentPlanOrder = planSorting.NotSetValue;
    let dragIconElements = null;

    $: mainPlans = separateUpcomingPlans
        ? data.plans.filter(p => p.start && p.start.getTime() <= upcomingSplitTime)
        : data.plans;
    $: upcomingPlans = separateUpcomingPlans
        ? data.plans.filter(p => p.start && p.start.getTime() > upcomingSplitTime)
        : [];

    $: if (data.loading) {
        setTimeout(() => {
            showLoading = data.loading;
        }, 100);
    } else {
        if (!newPlanUpdatePerformed) {
            newPlanUpdatePerformed = true;
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const planIdParam = urlParams.get("createdPlanId");

            if (planIdParam) {
                urlParams.delete("createdPlanId");
            }
        }

        showLoading = false;
    }

    $: if (orderDirty) {
        dragIconElements = document.getElementsByClassName("itsl-plan-card__drag");

        if (dragIconElements) {
            for (let i = 0; i < dragIconElements.length; i++) {
                dragIconElements[i].setAttribute("disabled", true);
                dragIconElements[i].classList.add("itsl-plan-card__drag--dirtystate");
            }
        }
    } else {
        dragIconElements = document.getElementsByClassName("itsl-plan-card__drag");

        if (dragIconElements) {
            for (let i = 0; i < dragIconElements.length; i++) {
                dragIconElements[i].removeAttribute("disabled");
                dragIconElements[i].classList.remove("itsl-plan-card__drag--dirtystate");
            }
        }
    }

    $: draggedElementStyle = dragSelectedPlan ? `top: ${draggedElementTop}px;` : "";

    onMount(() => {
        // Debounce saves to the plan order to limit requests and to avoid race conditions
        // when two changes are made in quick succession.
        const runDebounceSavePlansOrder = debounce(savePlanOrder, 1000);

        debounceSavePlanOrder = () => {
            orderDirty = true;

            runDebounceSavePlansOrder();
        };
    });

    async function showMore() {
        loadingError = null;
        const index = data.plans.length;

        const result = await loadMethod(config.publicAccessKey, true);

        if (result && result.error) {
            loadingError = result.error;
        }

        if (data.plans.length >= index && !isAutoloadingOn) {
            changeFocus(index);
        }
    }

    function changeFocus(index) {
        const planId = data.plans[index].id;
        const elementId = `#itsl-plan-card-${planId} > a`;

        if (document.querySelector(elementId) !== null) {
            document.querySelector(elementId).focus();
        }
    }

    function moveDown(element, index) {
        if (index >= data.plans.length - 1) {
            return;
        }

        const target = data.plans[index + 1];

        movePlan(element, target, false);

        debounceSavePlanOrder();
    }

    function moveUp(element, index) {
        if (index <= 0) {
            return;
        }

        const target = data.plans[index - 1];

        movePlan(element, target, true);

        debounceSavePlanOrder();
    }

    // Moves one plan to a target plan's position in the list, inserting
    // it either immediately before or after depending on insertBefore
    function movePlan(plan, target, insertBefore) {
        const newElements = [...data.plans];
        const fromIndex = newElements.findIndex(e => e.id === plan.id);

        newElements.splice(fromIndex, 1);

        const toIndex = newElements.findIndex(e => e.id === target.id) + (insertBefore ? 0 : 1);

        newElements.splice(toIndex, 0, plan);

        data.plans = newElements;
    }

    function keyboardDragStart(plan) {
        reorderDragHandleId = document.activeElement.id;

        keyboardReorder = true;
        keyboardReorderActiveId = plan.id;

        setTimeout(() => listElement.focus(), 0);
    }

    function listKeyDown(event) {
        if (!keyboardReorder) {
            return;
        }

        const index = data.plans.findIndex(e => e.id === keyboardReorderActiveId);
        const activePlan = data.plans[index];

        let targetPlanIndex;

        switch (normalizeKey(event.key)) {
            case Keys.UP:
                moveUp(activePlan, index);
                event.preventDefault();
                scrollPlanToView(activePlan.id);
                break;
            case Keys.DOWN:
                moveDown(activePlan, index);
                event.preventDefault();
                scrollPlanToView(activePlan.id);
                break;
            case Keys.ESCAPE:
            case Keys.SPACE:
            case Keys.TAB:
                event.stopPropagation();
                event.preventDefault();

                keyboardReorder = false;
                keyboardReorderActiveId = undefined;

                sourceSelectedPlan = data.plans.filter(e => e.id === activePlan.id);
                targetPlanIndex = data.plans.findIndex(e => e.id === activePlan.id) + 1;

                // Check if plan card will be placed last
                if (targetPlanIndex === data.plans.length) {
                    targetPlanIndex = data.plans.findIndex(e => e.id === activePlan.id) - 1;
                    targetSelectedPlanAfter = true;
                } else {
                    targetSelectedPlanAfter = false;
                }

                targetSelectedPlan = data.plans.filter(
                    e => e.id === data.plans[targetPlanIndex].id
                );
                debounceSavePlanOrder();

                if (reorderDragHandleId) {
                    setTimeout(() => document.getElementById(reorderDragHandleId).focus(), 0);
                }

                break;
        }
    }

    function scrollPlanToView(planId) {
        setTimeout(() => {
            document
                .getElementById(`itsl-plan-card-${planId}`)
                .scrollIntoView({ behavior: "smooth", block: "center" });
        }, 0);
    }

    function listBlur() {
        keyboardReorder = false;
        keyboardReorderActiveId = undefined;
    }

    // Start dragging by setting the dragSelectedplan and call pointerMove to position it correctly
    function dragStart(event, plan) {
        dragSelectedPlan = plan;

        pointerMove(event);
    }

    // While dragging an plan set its y position to the cursor's y pos and check for
    // other plans under the pointer to re-order the list
    function pointerMove(event) {
        if (dragSelectedPlan) {
            event.preventDefault();
            event.stopPropagation();

            const cursorPosY = event.touches ? event.touches[0].pageY : event.pageY;
            const scrollTop = document.body.parentElement.scrollTop;
            const offsetTop = scrollTop + listSection.getBoundingClientRect().y;

            draggedElementTop = cursorPosY - offsetTop;

            let elementRect;
            let elementTop;
            let dragOverElement;

            // Iterate over <li>s in the list and check if the pointer is within any of them
            for (let i = 0; i < listElement.children.length; i++) {
                elementRect = listElement.children[i].getBoundingClientRect();
                elementTop = elementRect.y + scrollTop;

                if (cursorPosY > elementTop && cursorPosY < elementTop + elementRect.height) {
                    dragOverElement = data.plans[i];

                    break;
                }
            }

            // If a plan (other than the dragged one) is hovered re-order the list to place the
            // dragged plan above or below the dragOverElement depending on pointer position.
            if (dragOverElement && dragOverElement.id !== dragSelectedPlan.id) {
                const insertBefore = cursorPosY - elementTop < elementRect.height / 2;

                movePlan(dragSelectedPlan, dragOverElement, insertBefore);
            }

            // If dragged element is at the bottom or top of the page start the scroll function
            if (cursorPosY - scrollTop > window.innerHeight - elementRect.height) {
                if (!dragScrolling) {
                    dragScrolling = true;
                    scrollWithDrag(true, cursorPosY);
                }
            } else if (cursorPosY - scrollTop < elementRect.height) {
                if (!dragScrolling) {
                    dragScrolling = true;
                    scrollWithDrag(false, cursorPosY);
                }
            } else {
                // Stop the scroll function when the cursor is out of the scroll area
                dragScrolling = false;
            }
        }
    }

    function scrollWithDrag(down) {
        // When scrolling down, if the bottom of the list is 25px above the bottom of the page
        if (down && listSection.getBoundingClientRect().bottom + 25 <= window.innerHeight) {
            dragScrolling = false;
        }

        // When scrolling up, if the page is already at the top, stop scrolling
        if (!down && document.body.parentElement.scrollTop <= 0) {
            dragScrolling = false;
        }

        if (!dragScrolling) {
            return;
        }

        const scrollAmount = down ? 3 : -3;

        document.body.parentElement.scrollTop += scrollAmount;

        // Move the dragged element to keep up with the moving page
        draggedElementTop += scrollAmount;

        // This function keeps calling itself until the max scroll is reached or dragScrolling is
        // false. This keeps the scroll smooth instead of jumping only when the pointer moves.
        setTimeout(() => {
            scrollWithDrag(down);
        }, 5);
    }

    // The list is reordered while dragging so once it is released trigger a save and stop
    // dragging the element
    function dragEnd() {
        if (dragSelectedPlan) {
            debounceSavePlanOrder();

            sourceSelectedPlan = data.plans.filter(e => e.id === dragSelectedPlan.id);
            let targetPlanIndex = data.plans.findIndex(e => e.id === dragSelectedPlan.id) + 1;

            // Check if plan card will be placed last
            if (targetPlanIndex === data.plans.length) {
                targetPlanIndex = data.plans.findIndex(e => e.id === dragSelectedPlan.id) - 1;
                targetSelectedPlanAfter = true;
            } else {
                targetSelectedPlanAfter = false;
            }

            targetSelectedPlan = data.plans.filter(e => e.id === data.plans[targetPlanIndex].id);

            dragSelectedPlan = null;
            dragScrolling = false;
        }
    }

    // Show "Own sorting" confirm modal if needed after drag & drop (for plans within single topic only)
    async function savePlanOrder() {
        if (isPlansInTopicView()) {
            const planOrder = await getPlanOrderInTopic(config.topicId);

            currentPlanOrder = planOrder;

            if (
                planOrder !== planSorting.YourOwnSortingValue &&
                !config.enableTopicPlansOwnSortingFixedOption
            ) {
                showReplaceSortingConfirmModal = true;
            } else {
                dragConfirmed = true;
                await savePlanOrderAfterConfirmation();
            }
        } else {
            dragConfirmed = true;
            await savePlanOrderAfterConfirmation();
        }
    }

    // Posts the new plan order to the API
    async function savePlanOrderAfterConfirmation() {
        orderDirty = true;

        if (isPlansInTopicView()) {
            if (!dragConfirmed) {
                await loadPlansForTopic(plansData, config.publicAccessKey, config.topicId);

                return;
            } else if (sourceSelectedPlan && targetSelectedPlan) {
                await dragAndDropPlanInTopic(
                    sourceSelectedPlan[0].id,
                    targetSelectedPlan[0].id,
                    sourceSelectedPlan[0].topic ? sourceSelectedPlan[0].topic.id : null,
                    targetSelectedPlanAfter,
                    currentPlanOrder
                );

                plansForTopicHasBeenReordered = true;
                await setPlanOrderInTopic(
                    sourceSelectedPlan[0].topic.id,
                    planSorting.YourOwnSortingValue
                );
                dispatch("plansForTopicHasBeenReordered");
            }
        } else if (sourceSelectedPlan && targetSelectedPlan) {
            await dragAndDropPlanInLesson(
                sourceSelectedPlan[0].id,
                targetSelectedPlan[0].id,
                sourceSelectedPlan[0].topic ? sourceSelectedPlan[0].topic.id : null,
                targetSelectedPlanAfter
            );
        }

        sourceSelectedPlan = undefined;
        targetSelectedPlan = undefined;
        targetSelectedPlanAfter = false;

        orderDirty = false;
    }

    function isPlansInTopicView() {
        return (
            !(loadMethod === loadWithoutDatePlans || loadMethod === loadAllPlans) &&
            config.topicId &&
            config.topicId > 0
        );
    }

    // On scroll check how far the scroll is from the bottom, when it enters the bottom
    // 100 px of the scrollable area, load more plans if there are more to load.
    async function onScroll() {
        const maxScroll = document.body.scrollHeight - window.innerHeight;

        if (document.documentElement.scrollTop > maxScroll - 100) {
            if (!scrollBottom) {
                scrollBottom = true;

                if (!data.loading && data.plans.length < data.total && isAutoloadingOn) {
                    await showMore();
                }
            }
        } else {
            scrollBottom = false;
        }
    }

    function toggleAutoloading() {
        isAutoloadingOn = !isAutoloadingOn;
    }

    async function replaceSortingModalClicked(event) {
        dragConfirmed = event.detail.confirmed;
        showReplaceSortingConfirmModal = false;
        await savePlanOrderAfterConfirmation();
    }
</script>
