<Modal describedBy="edit-topic-modal-description" bind:enabled clickOutsideCloses={false}>
    <span slot="title">{modalTitle}</span>
    <div slot="body" class="its-plans__edit-topic-body">
        <span id="edit-topic-modal-description">{i18n.topicInformation}</span>
        <TextField
            label={i18n.titleHeader}
            labelHidden={false}
            bind:value={name}
            type="text"
            placeholder={i18n.editTopicTitlePlaceholder}
            required={true}
            minlength={minTitleLength}
            maxlength={maxTitleLength}
        />
        <ColorPicker
            colours={config.topicColours}
            label={i18n.topicColourText}
            bind:selected={colorClass}
        />
    </div>

    <div slot="footer">
        <Button
            type="primary"
            htmlType="button"
            on:click={save}
            text={i18n.save}
            disabled={!name || !name.length || loading}
            {loading}
            large
        />
        <Button
            type="secondary"
            htmlType="button"
            on:click={cancel}
            text={i18n.cancel}
            disabled={loading}
            {loading}
            large
        />
    </div>
</Modal>

<script>
    import Modal from "@itslearning/prometheus/assets/modals/Modal/v2/Modal.svelte";
    import TextField from "@itslearning/prometheus/assets/inputs/TextField/v1/TextField.svelte";
    import Button from "@itslearning/prometheus/assets/inputs/Button/v1/Button.svelte";
    import ColorPicker from "@itslearning/prometheus/assets/inputs/ColorPicker/v1/ColorPicker.svelte";
    import config from "../../config";
    import i18n from "../../i18n";
    import { minTitleLength, maxTitleLength } from "../../constants";
    import { createEventDispatcher } from "svelte";
    import { formatLanguageString } from "@itslearning/atlas/strings/format";

    const dispatch = createEventDispatcher();

    export let enabled;
    export let topic;

    let name;
    let colorClass;
    let loading = false;

    $: modalTitle = formatLanguageString(i18n.editX, config.topicName);

    $: if (topic && enabled) {
        setInitialValues();
    }

    // To avoid changing the topic model until save is clicked this copies the values
    // locally for editing
    function setInitialValues() {
        name = topic.name;

        // Use the hex code on the topic model to find the corresponding color in the config
        const matchingColor = config.topicColours.find(c => c.hexCode === topic.color);

        colorClass = matchingColor ? matchingColor.className : "smog-1";

        loading = false;
    }

    // On save dispatch the changed values to the parent component so they can be saved
    function save() {
        // Find the color in the config matching the one picked in the picker to return
        const color = config.topicColours.find(c => c.className === colorClass);

        dispatch("save", { name, color });

        // Loading is set to true and the modal stays open
        // it is up to the parent component to save, then close the modal
        loading = true;
    }

    function cancel() {
        enabled = false;
    }
</script>
