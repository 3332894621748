<div class="its-plans__topic">
    <div class="its-plans__topic-header">
        <Link
            kind="standalone"
            iconClass="its-plans__topic-breadcrumbs"
            iconPlacement="start"
            href={breadcrumbsHref}
        >
            <div class="its-plans__topic-breadcrumbs-back"></div>
            <div class="its-plans__topic-breadcrumbs-location">{config.topicName}</div>
            <div class="its-plans__topic-breadcrumbs-slash">/</div>
        </Link>

        <h1 class="its-plans__topic-name">{topicName}</h1>

        {#if config.canEdit && config.topicId}
            <ButtonDropdown
                arrow={false}
                expanded={false}
                iconClass="its-plans__topic-menu-icon"
                iconOnly={true}
                id="topic-menu-button"
                text={formatLanguageString(i18n.editX, config.topicName)}
                responsive={false}
                items={settingsDropdownItems}
                textOnly={false}
                type="plain"
                on:selectedOption={dropdownOptionSelected}
            />
        {/if}
    </div>
    {#if $topicData.topic}
        <CustomFields bind:topic={$topicData.topic} {i18n} {config} />
    {/if}
    <h2 class="screen-reader">{i18n.plans}</h2>
    <div class="its-plans__topic-plans">
        <div
            class="its-plans__topic-plans-toolbar-container"
            class:its-plans__topic-plans-toolbar-container--readonly={!isEditPlansToolbarAvailable}
        >
            {#if isEditPlansToolbarAvailable}
                <div class="its-plans__topic-plans-toolbar">
                    <Link kind="primary-standalone" href={createNewPlanUrl} on:click={addPlan}>
                        {i18n.addPlan}
                    </Link>
                    <PlanActions
                        printAsTopic={config.topicId !== 0}
                        data={$plansData}
                        on:endOfActionOnSelectedPlans={updateAfterAction}
                        selectedView={plannerViews.topic}
                        {selectedPlanIds}
                    ></PlanActions>
                    {#if config.topicId}
                        <PlanOrderSelector
                            topicId={config.topicId}
                            {i18n}
                            {planOrder}
                            {showYourOwnSorting}
                            on:changePlanOrder={changePlanOrder}
                        ></PlanOrderSelector>
                    {/if}
                    {#if config.canEdit && config.isImportEnabled}
                        <UseReadyMadeContentLink
                            href={`/Courses/${
                                config.courseId
                            }/ImportContent?importTopic=false&topicId=${
                                config.topicId
                            }&returnUrl=${encodeURI(window.location.href)}`}
                            linktext={i18n.useReadyMadeContent}
                            emptyState={false}
                        ></UseReadyMadeContentLink>
                    {/if}
                </div>
            {/if}
            <div>
                <TableViewLink
                    {config}
                    {i18n}
                    isDataLoading={false}
                    backExtraParams={tableViewLinkBackExtraParams}
                />
            </div>
        </div>

        {#if $plansData.plans && $plansData.plans.length === 0 && !$plansData.loading}
            <!-- Need to check 'loading', otherwise EmptyState will appear while loading a page -->
            <EmptyState
                {iconName}
                title={titleEmptyTopicPage}
                description={descriptionEmptyTopicPage}
                showLink={config.canEdit}
                linkText={i18n.addPlan}
                href={createNewPlanUrl}
                on:click={addPlan}
            />
            {#if config.canEdit && config.isImportEnabled}
                <UseReadyMadeContentLink
                    href={`/Courses/${config.courseId}/ImportContent?importTopic=false&topicId=${
                        config.topicId
                    }&returnUrl=${encodeURI(window.location.href)}`}
                    linktext={i18n.useReadyMadeContent}
                    emptyState={true}
                ></UseReadyMadeContentLink>
            {/if}
        {/if}

        {#if $plansData.plans && $plansData.plans.length}
            <PlansView
                data={$plansData}
                loadMethod={loadMore}
                on:plansForTopicHasBeenReordered={plansForTopicHasBeenReordered}
                drag={!config.enableTopicPlansOwnSortingFixedOption ||
                    planOrder === planSorting.YourOwnSortingValue}
                {selectedPlanIds}
            />
        {/if}
    </div>

    <EditTopicModal
        bind:topic={$topicData.topic}
        bind:enabled={editTopicModalEnabled}
        on:save={e => saveTopicChanges(e.detail)}
    />

    <Modal bind:this={errorModal} showCancel={false} confirmText={i18n.ok} />
</div>

<script>
    import Link from "@itslearning/prometheus/assets/Navigation/Link/v1/Link.svelte";
    import EmptyState from "@itslearning/prometheus/assets/nodes/EmptyState/v1/EmptyState.svelte";
    import ButtonDropdown from "@itslearning/prometheus/assets/dropdowns/ButtonDropdown/v1/ButtonDropdown.svelte";
    import Modal from "@itslearning/prometheus/assets/modals/Modal/v2/Modal.svelte";

    import PlansView from "@its-ui/planner-common/components/PlansView/PlansView.svelte";
    import PlanActions from "@its-ui/planner-common/components/PlanActions/PlanActions.svelte";
    import EditTopicModal from "@its-ui/planner-common/components/EditTopicModal/EditTopicModal.svelte";
    import UseReadyMadeContentLink from "@its-ui/planner-common/components/UseReadyMadeContentLink/UseReadyMadeContentLink.svelte";
    import TableViewLink from "@its-ui/planner-common/components/TableViewLink/TableViewLink.svelte";

    import CustomFields from "../CustomFields/CustomFields.svelte";
    import PlanOrderSelector from "../PlanOrderSelector/PlanOrderSelector.svelte";

    import { formatLanguageString } from "@itslearning/atlas/strings/format";
    import i18n from "@its-ui/planner-common/i18n";
    import config from "@its-ui/planner-common/config";
    import {
        createPlan,
        connectToTopic,
        updateTopicTitle,
        updateTopicColor,
        getPlanOrderInTopic,
        setPlanOrderInTopic,
        resetPlansInTopicOrder
    } from "@its-ui/planner-common/api";
    import { plansData, topicData, selectedPlanIds } from "@its-ui/planner-common/logic/topicPage/stores";
    import { loadPlansForTopic } from "@its-ui/planner-common/helpers/loadPlansHelper";
    import {
        addBackDestinationParam,
        getAllQueryStringParametersFromCurrentUrl
    } from "@its-ui/planner-common/helpers/backLinkHelper";
    import {
        plannerViews,
        planSorting,
        columnTypes,
        columnVisibility,
        backLinkDestinations,
        noTopicTopicId,
        BACK_DESTINATION_QUERY_STRING_PARAMETER
    } from "@its-ui/planner-common/constants";
    import { onMount } from "svelte";

    /** Empty state variables **/
    const descriptionEmptyTopicPage = config.canEdit
        ? i18n.emptyState_TopicPage_Description_Teacher
        : i18n.emptyState_TopicPage_Description_Student;
    const iconName = "plans";
    const titleEmptyTopicPage = config.canEdit
        ? i18n.emptyState_Tab_Title_Teacher
        : i18n.emptyState_Title_Student;
    const isSelfPacedCourse =
        config.columnVisibilities[columnTypes.Date] === columnVisibility.Nobody;

    let editTopicModalEnabled = false;
    let planOrder = planSorting.NotSetValue;
    let showYourOwnSorting = false;
    let errorModal;
    let createNewPlanUrl = "#";
    let tableViewLinkBackExtraParams;

    $: topicName =
        config.topicId === noTopicTopicId
            ? config.withoutTopic
            : $topicData.topic
              ? $topicData.topic.name
              : "";

    $: settingsDropdownItems = [
        {
            options: [
                {
                    icon: "",
                    label: formatLanguageString(i18n.editX, config.topicName),
                    link: false,
                    value: { source: "edit" }
                }
            ]
        }
    ];

    $: breadcrumbsHref = config.publicAccessKey
        ? `/plans/public/${config.publicAccessKey}/topics`
        : `/plans/courses/${config.courseId}/topics?ChildId=${config.childId}`;

    $: {
        let backLinkParameters = {
            [BACK_DESTINATION_QUERY_STRING_PARAMETER]: backLinkDestinations.topic
        };

        const currentUrlQueryStringParameters = getAllQueryStringParametersFromCurrentUrl();

        if (currentUrlQueryStringParameters) {
            backLinkParameters = {
                ...backLinkParameters,
                ...currentUrlQueryStringParameters
            };
        }

        tableViewLinkBackExtraParams = backLinkParameters;
    }

    $: isEditPlansToolbarAvailable =
        config.canEdit && $plansData.plans && $plansData.plans.length !== 0;

    onMount(async () => {
        if (config.topicId > 0 && config.canEdit) {
            const result = await getPlanOrderInTopic(config.topicId);

            planOrder = result;

            if (planOrder === planSorting.YourOwnSortingValue) {
                showYourOwnSorting = true;
            } else if (
                planOrder === planSorting.NotSetValue &&
                !config.enableTopicPlansOwnSortingFixedOption
            ) {
                // Default for new topics: "Date ascending" for deadline-driven and "Creation date" for self-pace mode
                showYourOwnSorting = false;
                await setPlanOrderInTopic(config.topicId, isSelfPacedCourse ? planSorting.CreationDateValue : planSorting.DateAscendingValue);
                planOrder = isSelfPacedCourse ? planSorting.CreationDateValue : planSorting.DateAscendingValue;
                await updateAfterAction();
            } else if (
                isSelfPacedCourse &&
                (planOrder === planSorting.DateAscendingValue ||
                    planOrder === planSorting.DateDescendingValue)
            ) {
                // In the case when you switch to SelfPace mode, sorting by date is not allowed
                showYourOwnSorting = true;
                await resetPlansInTopicOrder(config.topicId, planOrder);
                await setPlanOrderInTopic(config.topicId, planSorting.YourOwnSortingValue);
                planOrder = planSorting.YourOwnSortingValue;
                await updateAfterAction();
            } else if (config.enableTopicPlansOwnSortingFixedOption) {
                showYourOwnSorting = true;

                if (planOrder === planSorting.NotSetValue) {
                    await setPlanOrderInTopic(config.topicId, isSelfPacedCourse ? planSorting.CreationDateValue : planSorting.DateAscendingValue);
                    planOrder = isSelfPacedCourse ? planSorting.CreationDateValue : planSorting.DateAscendingValue;
                    await updateAfterAction();
                }
            }
        }
    });

    const loadMore = () =>
        loadPlansForTopic(plansData, config.publicAccessKey, config.topicId, true, planOrder);

    function dropdownOptionSelected(event) {
        const data = event.detail.data;

        switch (data.source) {
            case "edit":
                editTopicModalEnabled = true;
                break;
        }
    }

    async function addPlan(event) {
        event.preventDefault();

        const planParams = {
            topic: null,
            start: "0000-00-00 00:00:00",
            stop: "0000-00-00 00:00:00"
        };

        const planData = await createPlan(planParams);

        // It's possible this is the "without topic" page in which case topic id is 0 and we should
        // not connect any topic
        if (config.topicId) {
            await connectToTopic(planData.planId, config.topicId);
        }

        const newPlanUrlWithPlanId = config.newPlanUrl.replace("/new", "/new/" + planData.planId);

        createNewPlanUrl = addBackDestinationParam(
            `${newPlanUrlWithPlanId}?pendingPlanId=${planData.planId}`
        );

        window.location.href = createNewPlanUrl;

        return false;
    }

    async function updateAfterAction() {
        await loadPlansForTopic(
            plansData,
            config.publicAccessKey,
            config.topicId,
            false,
            planOrder
        );
    }

    async function plansForTopicHasBeenReordered() {
        showYourOwnSorting = true;
        planOrder = await getPlanOrderInTopic(config.topicId);
    }

    function changePlanOrder(event) {
        planOrder = event.detail.planOrder;
        updateAfterAction();
    }

    async function saveTopicChanges({ name, color }) {
        try {
            if (name !== $topicData.topic.name) {
                await updateTopicTitle(config.topicId, name);
            }

            if ($topicData.topic.color !== color.hexCode) {
                await updateTopicColor(config.topicId, color.id);
            }
        } catch (e) {
            // If there is any error while saving log the error to the
            // console and show an error modal with an unknown error
            console.error(e);
            errorModal.prompt({ titleOnly: true, titleText: i18n.unknownError });

            return;
        } finally {
            editTopicModalEnabled = false;
        }

        // Apply the saved changes to the topic and plan models
        $topicData.topic.name = name;
        $topicData.topic.color = color.hexCode;
        $topicData.topic.borderColor = color.borderHexCode;

        if ($plansData.plans) {
            $plansData.plans = $plansData.plans.map(plan => ({ ...plan, topic: $topicData.topic }));
        }
    }
</script>
