import {
    setPlanDescription,
    setPlanTimeSpan,
    setPlanWeekAndDay,
    updatePlanEvents,
    setPlanTitle,
    setPlanClassHours,
    createPlan,
    togglePlansStatus,
    createTopic,
    connectToTopic,
    getTopic,
    removeTopic,
    deletePlanElement,
    setPlanImage,
    removePlanImage,
    removePlan,
    removePlans,
    copyPlans,
    setCustomFieldText,
    activatePlanElement,
    getCourses,
    deletePlanElementFromCourse,
    setPlansTimeSpan,
    setPlansWeekAndDay,
    setPlansTimeSpanEvents
} from "../api";

import { formatDateForApi, formatDateOnlyForApi, isDateOnlyMode } from "../helpers/dateHelper";
import config from "../config";
import { postNewPlannerKpi } from "../helpers/kpiHelper";
import { planData } from "../logic/planPage/stores";
import { getCoursesForCopyPageSize as pageSize } from "../constants";

export function updatePlanTitle(planId, title) {
    return setPlanTitle(planId, title);
}

export function updatePlanClassHours(planId, classHours) {
    return setPlanClassHours(planId, classHours);
}

export async function updatePlanDescription(planId, description) {
    const result = await setPlanDescription(planId, description);

    // Use the resulting safeHtml to set the display description
    planData.update(data => {
        data.plan.displayDescription = result.SafeHtml;
        data.plan.isDescriptionPlainText = result.CustomData.isPlainText;

        window.loadIframelyEmbedJs && setTimeout(() => window.loadIframelyEmbedJs(), 100);

        return data;
    });

    return result;
}

function getDatesForApi(start, stop) {
    let dateOnlyMode;

    if (start && stop) {
        if (isDateOnlyMode(start.getUTCMinutes(), stop.getUTCMinutes())) {
            start = formatDateOnlyForApi(start);
            stop = formatDateOnlyForApi(stop);
            dateOnlyMode = true;
        } else {
            start = formatDateForApi(start);
            stop = formatDateForApi(stop);
            dateOnlyMode = false;
        }
    } else {
        const emptyDateStr = "0000-00-00 00:00:00";

        start = emptyDateStr;
        stop = emptyDateStr;
    }

    return { start, stop, dateOnlyMode };
}

export async function updatePlanDates(planId, start, stop, connectedToEvent, activityIds) {
    if (connectedToEvent) {
        await updatePlanEvents(planId, activityIds);
    } else {
        const datesForApi = getDatesForApi(start, stop);

        // clear all connected events
        const activityIdArray = [];

        await updatePlanEvents(planId, activityIdArray);

        if (datesForApi.dateOnlyMode) {
            await setPlanWeekAndDay(planId, datesForApi.start, datesForApi.stop);
        } else {
            await setPlanTimeSpan(planId, datesForApi.start, datesForApi.stop);
        }
    }
}

export function createNewPlan(plan) {
    const datesForApi = getDatesForApi(plan.start, plan.stop);

    return createPlan({
        ...plan,
        start: datesForApi.start,
        stop: datesForApi.stop
    });
}

export function setPlanActive(planId, active, toggleElementsActivation) {
    return togglePlansStatus([planId], active, toggleElementsActivation, false);
}

export const setPlansActive = (
    planIds,
    active,
    toggleElementsActivation,
    isBulkAction = true,
    executeForAllPlans = false,
    topicIds = []
) =>
    togglePlansStatus(
        planIds,
        active,
        toggleElementsActivation,
        isBulkAction,
        executeForAllPlans,
        topicIds
    );

export async function setImage(planId, file) {
    await postNewPlannerKpi(config.kpi.kpiMeasurementAddPlanImage);

    return await setPlanImage(planId, file);
}

export function deleteImage(planId) {
    return removePlanImage(planId);
}

export function createNewTopic(title, colour) {
    return createTopic(title, colour);
}

export function connectPlanToTopic(planId, topicId) {
    return connectToTopic(planId, topicId);
}

export function getTopicForPlan(topicId) {
    return getTopic(topicId);
}

export function removeTopicFromPlan(planId) {
    return removeTopic(planId);
}

export function deleteResourceFromPlan(planId, elementId) {
    return deletePlanElement(planId, elementId);
}

export function deleteResourceFromCourse(planId, elementId) {
    return deletePlanElementFromCourse(planId, elementId);
}

export function removePlanFromCourse(planId) {
    return removePlan([planId]);
}

export function removePlansFromCourse(planIds, executeForAllPlans = false, topicIds = []) {
    return removePlans(planIds, executeForAllPlans, topicIds);
}

export async function getCoursesForCopy() {
    let pageIndex = 0;
    let result = await getCourses(pageIndex);
    let allCourses = result;

    while (result.length === pageSize) {
        pageIndex++;
        result = await getCourses(pageIndex);
        allCourses = [...allCourses, ...result];
    }

    return allCourses;
}

export async function copyPlansFromCourse(
    targetCourseId,
    targetTopicId,
    copyDates,
    copyResourcesAndActivities,
    overwriteAssessmentCriteria,
    plansIds,
    copyWithoutTopic,
    executeForAllPlans = false,
    topicIds = []
) {
    const result = await copyPlans(
        targetCourseId,
        targetTopicId,
        copyDates,
        copyResourcesAndActivities,
        overwriteAssessmentCriteria,
        plansIds,
        copyWithoutTopic,
        executeForAllPlans,
        topicIds
    );

    return result;
}

export async function updateCustomPlanField(planId, columnId, text) {
    const result = await setCustomFieldText(planId, columnId, text);

    // Use the resulting safeHtml to set the field's text for display
    planData.update(data => {
        const fieldIndex = data.plan.fields.findIndex(f => f.columnId === columnId);

        if (fieldIndex !== -1) {
            data.plan.fields[fieldIndex].displayText = result.SafeHtml;
            data.plan.fields[fieldIndex].isPlainText = result.CustomData.isPlainText;
        }

        return data;
    });

    return result;
}

export function activateResourceFromPlan(
    elementId,
    activate,
    isFolder,
    changeActivationForFolderContent
) {
    return activatePlanElement(elementId, activate, isFolder, changeActivationForFolderContent);
}

export async function updatePlansDates(
    planIds,
    start,
    stop,
    weekNumber,
    weekDays,
    order,
    executeForAllPlans,
    topicIds
) {
    const { start: startDate, stop: stopDate, dateOnlyMode } = getDatesForApi(start, stop);

    return await (dateOnlyMode
        ? setPlansWeekAndDay(
              planIds,
              startDate,
              stopDate,
              weekNumber,
              weekDays,
              order,
              executeForAllPlans,
              topicIds
          )
        : setPlansTimeSpan(
              planIds,
              startDate,
              stopDate,
              weekNumber,
              weekDays,
              order,
              executeForAllPlans,
              topicIds
          ));
}

export const updatePlansDatesEvents = (
    planIds,
    firstPlanEventIds,
    executeForAllPlans = false,
    topicIds = []
) => setPlansTimeSpanEvents(planIds, firstPlanEventIds, executeForAllPlans, topicIds);
