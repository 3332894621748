<div
    class="datetime-picker-modal__error"
    class:datetime-picker-modal__error--hidden={!setValidDatesErrorMessage}
    aria-live="assertive"
    role="alert"
>
    {i18n.youMustSetValidDates}
</div>
<div
    class="datetime-picker-modal__error"
    class:datetime-picker-modal__error--hidden={!(
        showStartStopErrorMessage ||
        showStartInvalidMessage ||
        showStopInvalidMessage
    )}
    aria-live="assertive"
    role="alert"
>
    {showStartStopErrorMessage
        ? i18n.errorStopDateLessThanStartDate
        : showStartInvalidMessage
          ? i18n.calendar_Recurring_invalid_from_date
          : i18n.calendar_Recurring_invalid_to_date}
</div>

<script>
    export let showStartStopErrorMessage;
    export let setValidDatesErrorMessage;
    export let showStartInvalidMessage;
    export let showStopInvalidMessage;
    export let i18n;
</script>
