<Button text={title} type="plain" htmlType="button" on:click={onClick} />

<Modal bind:this={rubricModal} confirmText={i18n.close} showCancel={false} wide>
    <span slot="title">{title}</span>
    <div slot="body">
        <iframe class="its-plans__rubric-frame" src={rubricUrl} {title} />
    </div>
</Modal>

<script>
    import Button from "@itslearning/prometheus/assets/inputs/Button/v1/Button.svelte";
    import Modal from "@itslearning/prometheus/assets/modals/Modal/v2/Modal.svelte";

    export let canEdit;
    export let i18n;
    export let config;
    export let rubricUrl;

    let rubricModal;

    if (!rubricUrl) {
        rubricUrl = config.rubricUrl;
    }

    $: title = canEdit ? i18n.manageRubric : i18n.viewRubric;

    function onClick() {
        // This is a modal with an iframe where the user can view or edit the
        // rubric. We do not need to do anything when they confirm/cancel it.
        rubricModal.prompt({});
    }
</script>
