<div class="use-ready-made-content {emptyState ? 'use-ready-made-content-emptystate' : ''}">
    <a class="use-ready-made-content-link" {href} aria-label={linktext}>
        <span class="use-ready-made-content-link--icon" />
        <span class="use-ready-made-content-link--text">{linktext}</span>
    </a>
</div>

<script>
    export let href;
    export let linktext;
    export let emptyState;
</script>
