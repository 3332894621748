import 'prometheus.aaa.scss';
import 'prometheus.modern.scss';

import './theme.aaa.scss';
import './theme.modern.scss';

import { registerDocumentClickBubblingOnTop } from "@itslearning/atlas/frames/IframeClickEventHelper";
import App from "./lib/App/App.svelte";
import { plansData, topicData } from "@its-ui/planner-common/logic/topicPage/stores";
import { loadPlansForTopic } from "@its-ui/planner-common/helpers/loadPlansHelper";
import { loadTopic } from "@its-ui/planner-common/helpers/loadTopicsHelper";
import i18n from "@its-ui/planner-common/i18n";
import config from "@its-ui/planner-common/config";
import "@its-ui/planner-common/helpers/CommonFunctions.js";
import "@its-ui/planner-common/helpers/TextEditor.js";

registerDocumentClickBubblingOnTop();

// @ts-ignore
const topPage = window.itslTop || window.top;

// If plans is opened in an itslearning iFrame set the selected menu item and collapse the tree menu
if (topPage) {
    if (typeof topPage.hideContentAreaTreeMenu === "function") {
        topPage.hideContentAreaTreeMenu();
    }

    if (typeof topPage.SelectCurrentNavigationItem === "function") {
        topPage.SelectCurrentNavigationItem("planner");
    }
}

parentPostMessage("askIfCustomTreeLinkNavigationRequired");

parentPostMessage("setIFrameTitleMessage", { iFrameTitle: i18n.title });

// Get the mount point for the App
const target = document.getElementById("plans");

// eslint-disable-next-line
const app = new App({ target });

if (config.topicId) {
    loadTopic(topicData, config.publicAccessKey, config.topicId);
}

loadPlansForTopic(plansData, config.publicAccessKey, config.topicId, false);

function parentPostMessage(name, data = {}) {
    window.parent.postMessage(JSON.stringify({ messageName: name, ...data }), "*");
}

export default app;
