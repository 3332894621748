/**
 * Constants used in the App
 *  (ie: endpoints, images, ...)
 */

export const backLinkDestinations = {
    current: 0,
    past: 1,
    withoutDate: 2,
    topic: 3,
    topics: 4,
    overview: 5,
    calendar: 6,
    myPage: 7,
    parentDashboard: 8,
    all: 10,
    tableView: 11
};

export const plannerViews = {
    notDefined: 0,
    current: 1,
    past: 2,
    withoutDate: 3,
    topic: 4,
    tableView: 5
};

export const plannerTabIds = {
    current: 0,
    past: 1,
    withoutDate: 2,
    topics: 3,
    all: 10
};

export const plannerSelfPacedTabIds = {
    topics: 0,
    all: 1
};

export const readyMadeContentTemplateTypes = {
    yourCourses: 0,
    courseTemplates: 1,
    fromPublishers: 2,
    otherSites: 3
};

export const endpoints = {
    // API Endpoints
    addCloudFilesToCourseFolder: planId =>
        `/restapi/personal/courses/{courseId}/folders/{folderId}/file/cloud/v1?planId=${planId}`,
    addFileRepoFilesToCourseFolder: planId =>
        `/restapi/personal/courses/{courseId}/folders/{folderId}/file/filerepo/v1?planId=${planId}`,
    getRecentCloudElements:
        "/restapi/personal/cloudproxy/recentfiles/{cloudService}/{filesCount}/{includeFolders}/{includeSharedFiles}",
    getRecentElements:
        "/restapi/personal/courses/{courseId}/resources/lastupdated/v1?elementType={elementType}",
    printPlans: (courseId, planIds, selectedView) =>
        `/plans/courses/${courseId}/printPlans?planIds=${planIds.join()}&selectedView=${selectedView}`,
    printPlansTbl: (courseId, planIds, selectedView) =>
        `/plans/courses/${courseId}/printPlansTbl?planIds=${planIds.join()}&selectedView=${selectedView}`,
    printAllPlansTbl: (courseId, topicIds, selectedView) =>
        `/plans/courses/${courseId}/printAllPlansTbl?topicIds=${topicIds.join()}&selectedView=${selectedView}`,
    printTopics: (courseId, topicIds, planIds) =>
        `/plans/courses/${courseId}/printTopics?topicIds=${topicIds.join()}${
            planIds && planIds.length ? `&planIds=${planIds.join()}` : ""
        }`,
    hasAnyPlan: courseId => `/plans/courses/${courseId}/hasAnyPlan`,
    getAvailabilityOfTemplateTypes: (courseId, topicId) =>
        `/restapi/personal/course/${courseId}/plans/availableTemplates/v1?topicId=${topicId}`,
    searchForCourses: (options, paging) =>
        `/restapi/course/templates/v1?search=${options.title}&PageNumber=${paging.pageIndex}&PageSize=${paging.pageSize}&sortDescending=${options.sortDescending}&availabilityType=${options.availabilityType}&organisationId=${options.organisationId}&selectedCourseId=${options.selectedCourseId}&selectedTopicId=${options.selectedTopicId}&isPush=${options.isPush}&requireTopics=${options.requireTopics}`,
    searchForPlannerTemplates: (options, paging) =>
        `/restapi/course/templates/shareableplanner/v1?search=${options.title}&PageNumber=${paging.pageIndex}&PageSize=${paging.pageSize}&sortDescending=${options.sortDescending}&publishersOnly=${options.publishersOnly}&organisationId=${options.organisationId}`,
    getTopicsFromCourse: (courseId, templateCourseId, paging) =>
        `/restapi/course/${courseId}/import/templates/course/${templateCourseId}/topics/v2?PageIndex=${paging.pageIndex}&PageSize=${paging.pageSize}`,
    getTopicsFromShareablePlanner: (courseId, templateCourseId, type) =>
        `/restapi/course/${courseId}/import/templates/shareableplanner/${templateCourseId}/topics/v1?elementType=${type}`,
    getPlansInTopicFromCourse: (courseId, templateCourseId, topicId, paging) =>
        `/restapi/course/${courseId}/import/templates/course/${templateCourseId}/topics/${topicId}/plans/v1?PageIndex=${paging.pageIndex}&PageSize=${paging.pageSize}`,
    getResourcesFromTemplateCourse: (courseId, templateCourseId, topicId, planId, paging, type) =>
        `/restapi/course/${courseId}/import/templates/course/${templateCourseId}/topics/${topicId}/plans/${planId}/resources/v1?PageIndex=${paging.pageIndex}&PageSize=${paging.pageSize}&type=${type}`,
    importResources: courseId => `/restapi/personal/course/${courseId}/plans/importResources/v1`,
    saveUserPreferencePageSize: courseId =>
        `/restapi/course/${courseId}/import/preferences/readymadecontent/pagesize/v1`
};

export const icons = {
    assignment: "icons/assignment.svg",
    course: "icons/graduation_cap.svg",
    googleDrive: "icons/xp/googledrive.svg",
    googleDriveDefault: "icons/xp/google_file.svg",
    library: "icons/library.svg",
    office365: "icons/office365.svg",
    onedrive: "icons/microsoft-onedrive-2019.svg",
    task: "icons/task.svg",
    test: "icons/test.svg",
    readyMadeContent: "icons/graduation_cap.svg"
};

export const resourceIcons = {
    AssignmentApp: icons.assignment,
    Assignment: icons.assignment,
    CustomActivity: icons.task,
    TestApp: icons.test,
    Test: icons.test
};

// Map received assignment and test element names to both possible menu items
// because the endpoint does not differentiate between the two so it could be either
export const elementNameMenuNameMap = {
    Assignment: ["AssignmentApp", "Assignment"],
    Test: ["TestApp", "Test"],
    CustomActivity: ["CustomActivity"],
    Note: ["Note"],
    Lesson: ["Page"],
    WebLink: ["Link"]
};

export const courseResourcesPageSize = 24;
export const getCoursesForCopyPageSize = 100;
export const pageSizeInCalendar = 3;
export const plansForTopicPageSize = 50;
export const defaultPageSize = 8;

export const columnTypes = {
    RichText: 0,
    Topic: 1,
    Plan: 3,
    PlanDescription: 4,
    Date: 5,
    ClassHours: 6,
    LearningObjectives: 7,
    Resources: 8,
    Activities: 9,
    Term: 10,
    Rubric: 11,
    Elements: 12,
    Thumbnail: 13,
    TopicThumbnail: 14
};

export const columnVisibility = {
    All: 1,
    Teacher: 0,
    Nobody: 2
};

export const learningObjectiveType = {
    Subject: 1,
    Folder: 2,
    LearningObjective: 3,
    Category: 4
};

export const plannerFieldType = {
    RichText: 0,
    Topic: 1,
    PlanTitle: 3,
    PlanDescription: 4,
    Date: 5,
    ClassHours: 6,
    LearningObjectives: 7,
    Resources: 8,
    Activities: 9,
    Term: 10,
    Rubric: 11,
    Elements: 12,
    Thumbnail: 13,
    TopicThumbnail: 14
};

export const resourceTypeToShow = {
    everything: 0,
    resources: 1,
    activities: 2
};

export const elementCategory = {
    Resource: 0,
    Activity: 1
};

export const courseAvailabilityType = {
    all: "All",
    mineOnly: "MineOnly",
    other: "Other"
};

export const listTypes = {
    courseList: 0,
    topicList: 1,
    planList: 2,
    resourceList: 3
};

export const plannerFieldTypeReversed = reverseObject(plannerFieldType);

function reverseObject(obj) {
    const newObj = {};

    Object.keys(obj).forEach(key => (newObj[obj[key]] = key));

    return newObj;
}

export const defaultFromHour = "08";
export const defaultToHour = "09";

export const plannerFilesUploadStatus = {
    none: 0,
    inProgress: 1,
    success: 2,
    error: 3
};

export const minTitleLength = 1;
export const maxTitleLength = 255;

export const topicsActionType = {
    Copy: 0,
    Print: 1,
    Delete: 2
};

export const plansActionType = {
    MakeVisible: 0,
    MakeNotVisible: 1,
    SetDate: 2,
    Copy: 3,
    Print: 4,
    Delete: 5,
    ConnectToCalendar: 6
};

export const plansCopyActionModal = {
    SelectCourse: 0,
    SelectTopic: 1,
    SelectWithoutTopic: 2
};

export const weekDays = {
    // for setPlansTimeSpan and setPlansWeekAndDay api
    Monday: 1,
    Tuesday: 2,
    Wednesday: 4,
    Thursday: 8,
    Friday: 16,
    Saturday: 32,
    Sunday: 64
};

export const planSorting = {
    NotSetValue: 1,
    CreationDateValue: 2,
    DateAscendingValue: 3,
    DateDescendingValue: 4,
    AlphabeticallyAZValue: 5,
    AlphabeticallyZAValue: 6,
    YourOwnSortingValue: 7
};

export const plansOrder = Object.freeze({
    default: 0,
    topic: 1,
    tableView: 2
});

export const TABLE_VIEW_PLANS_REFRESH_RESOURCES_EVENT_NAME =
    "itsl-tableview-plans-refresh-resources";

export const dateTimePickerModalFromSection = "datetime-picker-modal__from";
export const dateTimePickerModalToSection = "datetime-picker-modal__to";
export const datePickerButton = "prom-date-picker__picker-button";
export const fromDatePickerInputField = "prom-input-date-picker-from-input";
export const toDatePickerInputField = "prom-input-date-picker-to-input";

export const noTopicTopicId = 0;

export const BACK_DESTINATION_QUERY_STRING_PARAMETER = "BackDestination";
export const TABLE_VIEW_MODE_QUERY_STRING_PARAMETER = "TableViewMode";
export const TOPIC_ID_DESTINATION_QUERY_STRING_PARAMETER = "TopicId";

export const planType = Object.freeze({
    deadLineDriven: 0,
    onePlanAtATime: 1,
    allPlansAtOnce: 2
});

export const onePlanAtATimePlanStatus = Object.freeze({
    locked: 0,
    unlocked: 1,
    inProgress: 2,
    reopened: 3,
    completed: 4
});
