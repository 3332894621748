import config from "../config";
import { postKpi } from "@itslearning/atlas/network/kpi";

export const postNewPlannerKpi = async measurementId => {
    // Failed attempt to post KPI should not intyerrupt interaction with planner.
    try {
        await postKpi({
            url: config.kpi.kpiUrl,
            context: config.kpi.kpiContext,
            section: config.kpi.kpiSectionNewPlanner,
            measurement: measurementId
        });
    } catch (error) {
        // So just log error into browser console.
        console.log(error);
    }
};
