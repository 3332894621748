import { backLinkDestinations } from "../constants";
import config from "../config";

export function addBackDestinationParam(destinationUrl, extraParams) {
    const url = new URL(location.origin + destinationUrl);
    const plansRegex = /plans\/(?:courses|public)\/\w+\/(current|past|withoutdate|all)\//i;
    const tableViewRegex = /plans\/courses\/(\d+)\/tableView/;
    const tableViewRegexMatch = location.pathname.match(tableViewRegex);
    const plansRegexMatch = location.pathname.match(plansRegex);
    const topicRegex = /\/plans\/(?:courses|public)\/\w+\/topics\/(\d+)/i;
    const topicRegexMatch = location.href.match(topicRegex);
    const topicsRegex = /\/plans\/(?:courses|public)\/\w+\/topics(?:[/]|[?]).*$/i;
    const topicsRegexMatch = location.href.match(topicsRegex);
    const parentDashboardRegex = /.+LocationId=(\d+).+&SummaryDashboardType=Parent/i;
    const dashboardUrl = "/Dashboard/Dashboard.aspx";

    if (plansRegexMatch) {
        switch (plansRegexMatch[1]) {
            case "current":
                url.searchParams.append("BackDestination", backLinkDestinations.current);
                break;
            case "past":
                url.searchParams.append("BackDestination", backLinkDestinations.past);
                break;
            case "withoutdate":
                url.searchParams.append("BackDestination", backLinkDestinations.withoutDate);
                break;
            case "all":
                url.searchParams.append("BackDestination", backLinkDestinations.all);
                break;
            default:
                url.searchParams.append("BackDestination", backLinkDestinations.current);
                break;
        }
    } else if (topicRegexMatch) {
        url.searchParams.append("BackDestination", backLinkDestinations.topic);

        if (!url.searchParams.get("TopicId")) {
            url.searchParams.append("TopicId", topicRegexMatch[1]);
        }
    } else if (topicsRegexMatch) {
        url.searchParams.append("BackDestination", backLinkDestinations.topics);
    } else if (tableViewRegexMatch) {
        url.searchParams.append("BackDestination", backLinkDestinations.tableView);

        if (extraParams) {
            url.searchParams.append("BackData", JSON.stringify(extraParams));
        }
    } else if (location.pathname.includes("Course/course.aspx")) {
        url.searchParams.append("BackDestination", backLinkDestinations.overview);
    } else if (location.pathname.includes(config.calendarUrl)) {
        url.searchParams.append("BackDestination", backLinkDestinations.calendar);
        url.searchParams.append("BackData", JSON.stringify(extraParams));
    } else if (
        location.pathname.includes(config.dashboardUrl ? config.dashboardUrl : dashboardUrl)
    ) {
        const parentDashboardRegexMatch = location.href.match(parentDashboardRegex);

        if (parentDashboardRegexMatch) {
            url.searchParams.append("BackDestination", backLinkDestinations.parentDashboard);
            url.searchParams.append(
                "BackData",
                JSON.stringify({ HierarchyId: parentDashboardRegexMatch[1] })
            );
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const childIdFromQueryParameter = urlParams.get("ChildId");

            if (childIdFromQueryParameter && childIdFromQueryParameter > 0) {
                url.searchParams.append("ChildId", childIdFromQueryParameter);
            }
        } else {
            url.searchParams.append("BackDestination", backLinkDestinations.myPage);
        }
    } else if (location.pathname.includes("tableView")) {
        url.searchParams.append("BackDestination", backLinkDestinations.tableView);
    } else {
        url.searchParams.set("BackDestination", backLinkDestinations.current);

        if (extraParams) {
            url.searchParams.append("BackData", JSON.stringify(extraParams));
        }
    }

    if (!url.searchParams.get("ChildId") && config.childId > 0) {
        url.searchParams.append("ChildId", config.childId);
    }

    return url.href;
}

export const getAllQueryStringParametersFromCurrentUrl = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    if (urlSearchParams.size > 0) {
        const queryStringParameters = {};

        for (const [parameterKey, parameterValue] of urlSearchParams) {
            queryStringParameters[parameterKey] = parameterValue;
        }

        return queryStringParameters;
    }

    return null;
};

export const getDestinationUrlWithExtraParametersFromCurrentUrl = (
    destinationUrl,
    queryStringParameters = null
) => {
    if (!destinationUrl) {
        return;
    }

    const urlSearchParams = new URLSearchParams(window.location.search);

    const url = new URL(location.origin + destinationUrl);

    const addQueryStringParameters = queryStringParameters => {
        if (!queryStringParameters) {
            return;
        }

        for (const [parameterKey, parameterValue] of Object.entries(queryStringParameters)) {
            url.searchParams.set(parameterKey, parameterValue);
        }
    };

    const backdataQueryStringParameter = urlSearchParams.get("BackData");

    if (backdataQueryStringParameter) {
        addQueryStringParameters(JSON.parse(backdataQueryStringParameter));
    }

    addQueryStringParameters(queryStringParameters);

    return url.href;
};

export const hasParam = (destinationUrl, param) => {
    const urlSegments = destinationUrl.split('?');
    const urlSearchParams = new URLSearchParams(urlSegments[1]);

    return urlSearchParams.has(param);
};

export const removeParam = (destinationUrl, param) => {
    const urlSegments = destinationUrl.split('?');
    const urlSearchParams = new URLSearchParams(urlSegments[1]);

    urlSearchParams.delete(param);

    return urlSegments[0]+'?'+decodeURIComponent(urlSearchParams.toString());
};

export const setParam = (destinationUrl, param, value) => {
    const urlSegments = destinationUrl.split('?');
    const urlSearchParams = new URLSearchParams(urlSegments[1]);

    urlSearchParams.set(param, value);

    return urlSegments[0]+'?'+decodeURIComponent(urlSearchParams.toString());
};
