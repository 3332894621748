const NO_TOPIC = Symbol("NoTopicImages");

export function createPlaceholderImagesProvider(topicColours, defaultPlanImages) {
    if (!defaultPlanImages) {
        new Error("Default plan images are required.");
    }

    const topicPlaceholderLookup = new Map([
        ...defaultPlanImages.topicColourImages.map(tc => [
            tc.colorSchemaName,
            {
                imageIndex: getRandomImageIndex(tc.imageUrls.length),
                imageUrls: tc.imageUrls
            }
        ]),
        [
            NO_TOPIC,
            {
                imageIndex: getRandomImageIndex(defaultPlanImages.noTopicImages.length),
                imageUrls: defaultPlanImages.noTopicImages
            }
        ]
    ]);

    return {
        getImageForTopic: topic => {
            if (topic && !topic.color) {
                return null;
            }

            let topicColor = NO_TOPIC;

            if (topic) {
                const matchingColor = topicColours.find(c => c.hexCode === topic.color);

                if (matchingColor) {
                    topicColor = matchingColor.className;
                }
            }

            const defaultImages = topicPlaceholderLookup.get(topicColor);

            if (!defaultImages) {
                return null;
            }

            const currentImageIndex = defaultImages.imageIndex;

            topicPlaceholderLookup.set(topicColor, {
                ...defaultImages,
                imageIndex:
                    currentImageIndex + 1 >= defaultImages.imageUrls.length
                        ? 0
                        : currentImageIndex + 1
            });

            return defaultImages.imageUrls[currentImageIndex];
        }
    };
}

const getRandomImageIndex = maxAllowedIndex => Math.floor(Math.random() * maxAllowedIndex);
